import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const ProfileMetaDataPanel = ({ user }) => {
  return (
    <Box mt={3}>
      <Box>
        <Typography align="left" style={{ fontWeight: '540', fontSize: '14px' }}>
          Stance: <span
          style={{ fontWeight: '400' }}
        >
          {user.stance}
          </span>
        </Typography>
      </Box>

      <Box>
        <Typography align="left" style={{ fontWeight: '540', fontSize: '14px' }}>
          Discipline: <span
            style={{ fontWeight: '400' }}
          >
          {user.discipline}
          </span>
        </Typography>
      </Box>

      <Box>
        <Typography align="left" style={{ fontWeight: '540', fontSize: '14px' }}>
          Signature moves: <span
          style={{ fontWeight: '400' }}
        >
            {user.signatureMoves}
          </span>
        </Typography>
      </Box>

      <Box>
        <Typography align="left" style={{ fontWeight: '540', fontSize: '14px' }}>
          Sponsors: <span
          style={{ fontWeight: '400' }}
        >
          {user.sponsors}
          </span>
        </Typography>
      </Box>

      <Box mb={2} mt={2}>
        <Typography align="left" style={{ fontSize: '14px' }}>
          {user.biography}
        </Typography>
      </Box>

    </Box>
  )
};

export default ProfileMetaDataPanel;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  useSetCreateSpotMarkerLocationRequest,
  useSetShowCreateSpotMarkerRequest
} from '../../flux/effects/spot.effects';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  card: {
    position: 'absolute',
    top: '200px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '272px',
    height: '105px',
    backgroundColor: 'white',
    zIndex: 2
  },
  text: {
    textAlign: 'center',
    fontSize: '16px',
  },
  submitButtonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: '10px 10px 0 10px',
  },
  cancelBtn: {
    width: '72px',
    height: '30px',
    marginTop: '5px',
  },
  submitBtn: {
    width: '72px',
    height: '30px',
    marginLeft: "20px",
    marginRight: "18px",
    marginTop: '5px',
    boxShadow: 'none',
    color: 'rgba(0, 0, 0, 0.87)'
  },
}));

export default function ConfirmSpotLocation({ setSpotModalOpen }) {
  const classes = useStyles();
  const setShowCreateSpotMarker = useSetShowCreateSpotMarkerRequest();
  const setCreateSpotMarkerLocation = useSetCreateSpotMarkerLocationRequest();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.text} variant="body2" component="p">
            Move the marker to your spot.
          </Typography>
          <div className={classes.submitButtonContainer}>
            <Button
              variant="outlined"
              className={classes.cancelBtn}
              onClick={() => {
                setCreateSpotMarkerLocation({lat: null, lon: null})
                setShowCreateSpotMarker(false)
              }}
            >
              CANCEL
            </Button>
            <Button
              className={classes.submitBtn}
              color="secondary"
              variant="contained"
              onClick={() => {
                setSpotModalOpen(true);
                setShowCreateSpotMarker(false);
              }}
            >
              SAVE
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Avatar from '@material-ui/core/Avatar';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Badge from '@material-ui/core/Badge';
import Fade from '@material-ui/core/Fade';
import ProfileForm from './ProfileForm';
import ProfileFormButtons from './ProfileFormButtons';
import IconButton from '@material-ui/core/IconButton';
import useS3Upload from '../../imageUpload/useS3Upload';
import {
  useUpdateUserProfileRequest,
  useUserProfile,
  useNewProfileImage,
  useSetNewProfileImageRequest,
} from '../../flux/effects/profile.effects';
import ProfileFormContainer from './ProfileFormContainer';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: 395,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[ 5 ],
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'scroll',
    borderRadius: '4px',
    "&:focus": {
      outline: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
      justifyContent: 'center',
    }
  },
  large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  avatarBadge: {
    color: "white"
  },
  closeContainer: {
    width: '100%',
  },
  badgeRoot: {
    marginBottom: theme.spacing(3),
  },
  badgeContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
  },
  formRoot: {
    marginBottom: theme.spacing(1),
  },
  iconCloseButton: {
    backgroundColor: "rgb(84, 83, 83, 0.7)",
    "&:hover": {
      backgroundColor: "rgb(84, 83, 83)"
    },
  }
}));


export default function ProfileModal({ profileModalOpen, setProfileModalOpen }) {
  const classes = useStyles();
  const userProfile = useUserProfile();
  const newUserProfileImage = useNewProfileImage();
  const updateNewProfileImage = useSetNewProfileImageRequest();

  const [displayName, setDisplayName] = useState();
  const [photo, setPhoto] = useState();

  useEffect(() => {
    setDisplayName(displayName ? displayName : userProfile.displayName);
    setPhoto(userProfile.photo);
    return () => {
      updateNewProfileImage(null);
    }
  }, [userProfile]);

  const onUploadStart = () => console.log("onUploadStart");
  const onUploadReady = (pendingImage, imageUrl) => {
    setPhoto(imageUrl);
    updateNewProfileImage(imageUrl);
  };

  const onError = () => console.log("onError");

  const { getRootProps, getInputProps, open } = useS3Upload({
    imageId: userProfile.userId,
    onUploadStart,
    onUploadReady,
    onError
  });

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={profileModalOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={profileModalOpen}>
        <div className={classes.paper}>
          <div className={classes.titleContainer}>
            <p className={classes.title}>Edit profile</p>
          </div>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
          </div>
          <div className={classes.badgeContainer}>
            {newUserProfileImage ? (
              <Badge
                className={classes.badgeRoot}
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={
                  <IconButton color="secondary" className={classes.iconCloseButton} onClick={open}>
                    <CameraAltIcon className={classes.avatarBadge} overlap="circle"/>
                  </IconButton>
                }
              >
                <Avatar
                  className={classes.large}
                  src={newUserProfileImage}
                />
              </Badge>
            ) : (
              <Badge
                className={classes.badgeRoot}
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={
                  <IconButton color="secondary" className={classes.iconCloseButton} onClick={open}>
                    <CameraAltIcon className={classes.avatarBadge} overlap="circle"/>
                  </IconButton>
                }
              >
                <Avatar
                  className={classes.large}
                  src={photo}
                />
              </Badge>
            )
            }
          </div>

          <div className={classes.formRoot}>
            <ProfileFormContainer
              photo={photo}
              onClose={() => setProfileModalOpen(false)}
              onSuccess={() => setProfileModalOpen(false)}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '8px',
    width: 291,
    maxWidth: 291,
    maxHeight: 72,
    margin: '8px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 75,
  },
  iconContainer: {
    backgroundColor: '#EEEEEE',
    display: 'flex',
    height: 72,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#556168'
  }
}));

export default function NoLocalsCard({ onAdd }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined" elevation={0}>
      <CardMedia
        className={classes.cover}
        height="72"
      >
        <Box className={classes.iconContainer}>
          <PersonPinCircleIcon fontSize="large" />
        </Box>
      </CardMedia>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography align="left" style={{ fontSize: '15px' }}>
            No locals at present.
          </Typography>
          <Typography align="left" variant="subtitle1" color="textSecondary" style={{ fontSize: '13px' }}>
            <Button
              color="secondary"
              style={{ textTransform: 'none', paddingLeft: 0, paddingTop: 0 }}
              onClick={onAdd}
            >
              Add yourself
            </Button>
          </Typography>
        </CardContent>
      </div>

    </Card>
  );
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BaseCard from './BaseCard';
import { useSelectedSpot } from '../../flux/effects/spot.effects';
import { useSelectedUserProfile } from '../../flux/effects/profile.effects';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '50%',
    height: 600,
  },
  gridTile: { borderRadius: '8px' },
  rootGridContainer: {
    display: 'flex',
    // flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%'
  },
  gridColumn: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // height: 600,
    width: '50%',
    // overflowY: 'hidden'
  },
  mainImage: {
    borderRadius: '8px',
    width: '332px',
  },
  subImage: {
    borderRadius: '8px',
    height: '166px',
    width: '166px',
  }
}));

const FootageByCard = (props) => {
  const classes = useStyles();
  const user = useSelectedUserProfile();

  return (
    <BaseCard
      rootStyles={{ height: '752px' }}
      onSeeAll={() => console.log('See All')}
      title={`Footage by ${user.displayName}`}
    >
      <div className={classes.root}>
      </div>

    </BaseCard>
  );
};

export default FootageByCard;
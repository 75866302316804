import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useAddSpotLocalRequest, useRemoveSpotLocalRequest } from '../../flux/effects/spot.effects';

const useStyles = makeStyles((theme) => ({
  localBtn: {
    color: 'black',
    marginRight: theme.spacing(2),
  },
}));

const SpotLocalButton = ({ userId, spot }) => {
  const classes = useStyles();
  const addLocal = useAddSpotLocalRequest();
  const removeLocal = useRemoveSpotLocalRequest();

  const isLocal = () => {
    if (!spot.locals) {
      return false;
    }
    return spot.locals.includes(userId)
  };

  return (
    <>
      {isLocal() ? (
        <Button
          className={classes.localBtn}
          color="secondary"
          variant="contained"
          disabled={!userId}
          disableElevation
          onClick={() => removeLocal(userId, spot)}
        >
          I'M NOT A LOCAL
        </Button>
      ) : (
        <Button
          className={classes.localBtn}
          color="secondary"
          variant="contained"
          disabled={!userId}
          disableElevation
          onClick={() => addLocal(userId, spot)}
        >
          I'M A LOCAL
        </Button>
      )}
    </>
  );
};

export default SpotLocalButton;
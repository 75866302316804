import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ProfileIcon from '@material-ui/icons/Person';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LogoutIcon from '@material-ui/icons/PowerOff';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
const useStyles = makeStyles((theme) => ({}));


export default function NavbarMenu({
  setAnchorEl, anchorEl, menuId, onMenuClose, onProfileOpen, user, onLogout, ...props
}) {
  const classes = useStyles();
  const isMenuOpen = Boolean(anchorEl);

  const logoutWithRedirect = () =>
    onLogout({
      returnTo: window.location.origin
    });

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={onMenuClose}
    >
      <Hidden smUp>
        <MenuItem>
          <ListItemIcon>
            <NotificationsIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </MenuItem>
      </Hidden>
      <MenuItem onClick={() => {
        onMenuClose()
        onProfileOpen()
      }}>
        <ListItemIcon>
          <ProfileIcon color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </MenuItem>
      <MenuItem onClick={() => {
        onMenuClose()
        logoutWithRedirect();
      }}>
        <ListItemIcon>
          <LogoutIcon color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Log out" />
      </MenuItem>
    </Menu>
  );
}
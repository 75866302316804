import axios from 'axios';
import{ ROOT_API_URL } from '../app.config';

const SPOTS_URL = `${ROOT_API_URL}/api/v1/spots`;
const SPOTS_BY_USER_URL = `${ROOT_API_URL}/api/v1/spotsbyuser`;
const SPOTS_BY_NEAR_BY_URL = `${ROOT_API_URL}/api/v1/spotsbynearby`;

export const creatSpot = payload => {
  return axios.post(SPOTS_URL, payload);
};

export const fetchSpot = spotId => {
  return axios.get(`${SPOTS_URL}/${spotId}`)
}

export const updateSpot = (spotId, payload) => {
  return axios.put(`${SPOTS_URL}/${spotId}`, payload)
}

export const fetchSpots = () => {
  return axios.get(SPOTS_URL);
};

export const fetchSpotByUserId = userId => {
  return axios.get(`${SPOTS_BY_USER_URL}/${userId}`)
}

export const fetchSpotByNearBy = spotId => {
  return axios.get(`${SPOTS_BY_NEAR_BY_URL}/${spotId}`)
}

import axios from 'axios';
import { MAPBOX_API_KEY } from '../config';

const REVERSE_GEOCODING_URL = "https://api.mapbox.com/geocoding/v5/mapbox.places"


export const reverseGeoLookUp = (lat, lon) => {
  return axios.get(`${REVERSE_GEOCODING_URL}/${lon},${lat}.json?access_token=${MAPBOX_API_KEY}`);
};


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BaseCard from './BaseCard';
import { GridList } from '@material-ui/core';
import GridListTile from '@material-ui/core/GridListTile';
import { useSelectedSpot } from '../../flux/effects/spot.effects';
import { getImagePath } from '../../utils';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '50%',
    height: 600,
  },
  gridTile: { borderRadius: '8px' },
  rootGridContainer: {
    display: 'flex',
    // flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%'
  },
  gridColumn: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // height: 600,
    width: '50%',
    // overflowY: 'hidden'
  },
  mainImage: {
    borderRadius: '8px',
    width: '332px',
  },
  subImage: {
    borderRadius: '8px',
    height: '166px',
    width: '166px',
    // paddingLeft: '10px'
  }
}));

const SpotGalleryCard = (props) => {
  const classes = useStyles();
  const spot = useSelectedSpot();

  return (
    <BaseCard
      rootStyles={{ height: '752px' }}
      onSeeAll={() => console.log('See All')}
      title="Spot gallery"
    >
      <div className={classes.root}>
        {spot && spot.photos &&
          <Box className={classes.rootGridContainer}>
            <Box className={classes.gridColumn}>
              <img className={classes.mainImage} src={getImagePath(spot.photos[0])} alt={spot.photos[0]}/>
              {/*<img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
              {/*<img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
              {/*<img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
              {/*<img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
              {/*<img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
              {/*<img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
              {/*<img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
              {/*<img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
              {/*<img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
            </Box>

            {/*<Box className={classes.gridColumn}>*/}
            {/*  <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
            {/*  <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
            {/*  <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
            {/*  <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
            {/*  <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
            {/*  <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
            {/*  <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
            {/*  <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
            {/*</Box>*/}
          </Box>

          // <GridList cellHeight={140} className={classes.gridList} cols={2} spacing={10}>
          //
          //   <GridListTile cols={2} rows={2} classes={{ tile: classes.gridTile }}>
          //       <img className={classes.mainImage} src={getImagePath(spot.photos[0])} alt={spot.photos[0]}/>
          //   </GridListTile>
          //   <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>
          //     <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>
          //   </GridListTile>
          //   <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>
          //     <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>
          //   </GridListTile>
          //   <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>
          //     <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>
          //   </GridListTile>
          //   <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>
          //     <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>
          //   </GridListTile>
          // </GridList>
        }
        {/*<GridList cellHeight={140} className={classes.gridList} cols={2} spacing={10}>*/}
        {/*  <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>*/}
        {/*    <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
        {/*  </GridListTile>*/}
        {/*  <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>*/}
        {/*    <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
        {/*  </GridListTile>*/}
        {/*  <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>*/}
        {/*    <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
        {/*  </GridListTile>*/}
        {/*  <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>*/}
        {/*    <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
        {/*  </GridListTile>*/}
        {/*  <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>*/}
        {/*    <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
        {/*  </GridListTile>*/}
        {/*  <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>*/}
        {/*    <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
        {/*  </GridListTile>*/}
        {/*  <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>*/}
        {/*    <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
        {/*  </GridListTile>*/}
        {/*  <GridListTile cols={1} rows={1} classes={{ tile: classes.gridTile }}>*/}
        {/*    <img className={classes.subImage} src={getImagePath("no-image.jpg")} alt={"no-image.jpg"}/>*/}
        {/*  </GridListTile>*/}
        {/*</GridList>*/}
      </div>

    </BaseCard>
  );
};

export default SpotGalleryCard;
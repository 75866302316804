import React from 'react';
import { useUserProfile } from '../../flux/effects/profile.effects';
import SpotCreateModal from './SpotCreateModal';
import SignInToCreateSpotModal from './SignInToCreateSpotModal';


export default function CreateSpotModal({ spotModalOpen, setSpotModalOpen }) {
  const userProfile = useUserProfile();

  return (
    <React.Fragment>
    {userProfile.userId ? (
      <SpotCreateModal
        spotModalOpen={spotModalOpen}
        setSpotModalOpen={setSpotModalOpen}
      />
      ): (
      <SignInToCreateSpotModal
        spotModalOpen={spotModalOpen}
        setSpotModalOpen={setSpotModalOpen}
      />
    )}
    </React.Fragment>
  );
}

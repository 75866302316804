import { useDropzone } from 'react-dropzone';
import { getPreSignedImageURL } from '../services/profile.service';

export default function useS3Upload({
   imageId,
   onUploadStart,
   onUploadReady,
   onError,
}) {
  async function handleDrop([pendingImage]) {
    onUploadStart();

    const { data: { url: presignedUploadUrl } }  = await getPreSignedImageURL(imageId);
    
    // Upload the image to our pre-signed URL.
    const response = await fetch(
      new Request(presignedUploadUrl, {
        method: 'PUT',
        body: pendingImage,
        headers: new Headers({
          'Content-Type': 'image/*',
        }),
      }),
    );

    console.log('response', response);
    if (response.status !== 200) {
      // The upload failed, so let's notify the caller.
      onError();
      return;
    }

    const imageUrl = response.url.split("?")[0]
    // Let the caller know that the upload is done, so that it can send the URL
    // to the backend again, persisting it to the database.
    onUploadReady(pendingImage, imageUrl);
  }

  return useDropzone({
    accept: 'image/*',
    // disabled: typeof presignedUploadUrl !== 'string',
    onDrop: handleDrop,
  });
}
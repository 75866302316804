import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    width: '384px',
    backgroundColor: 'white',
    position: 'absolute',
    // [theme.breakpoints.down('xs')]: {
    ['@media (max-width:411px)']: {
      width: '92%',
    },
    ['@media (max-width:376px)']: {
      width: '91.5%',
    },
  },
  card: {
    backgroundColor: 'blue',
  },
  cardContent: {
    padding: '8px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    backgroundColor: 'white',
  },
  iconButton: {
    padding: 10,
    backgroundColor: 'white',
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CustomInputBase({ onClear }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Paper id="searchBox--FOO" className={classes.root}>
          <IconButton
            id="searchBoxIcon"
            className={classes.iconButton}
            aria-label="search"
            onClick={onClear}
          >
            <CloseIcon />
          </IconButton>
          <Divider className={classes.divider} orientation="vertical"/>
          <IconButton color="primary" className={classes.iconButton} aria-label="directions" disabled>
            {/*<DirectionsIcon/>*/}
            <Icon>
              <img src="ss_logo.svg" />
            </Icon>
          </IconButton>
        </Paper>
      </CardContent>
    </Card>
  );
}
import * as React from "react";

function StreetIcon(props) {
  return (
    <svg
      width="2.5em"
      height="2.5em"
      viewBox="0 0 38 42"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <filter
          x="-18.8%"
          y="-75%"
          width="137.5%"
          height="250%"
          filterUnits="objectBoundingBox"
          id="Street_svg__a"
        >
          <feGaussianBlur stdDeviation={1} in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <ellipse
          fill="#000"
          opacity={0.3}
          filter="url(#Street_svg__a)"
          cx={20}
          cy={39}
          rx={8}
          ry={2}
        />
        <rect
          stroke="#000"
          fill="#FFCA28"
          transform="rotate(45 19.799 19.799)"
          x={6.299}
          y={6.299}
          width={27}
          height={27}
          rx={3}
        />
        <path
          d="M13 23v-4.002c0-1.104.897-1.998 2.006-1.998h9.988c1.108 0 2.006.898 2.006 1.998V23"
          stroke="#000"
          strokeWidth={2}
        />
      </g>
    </svg>
  );
}

export default StreetIcon;


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import { Card, CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  card: {
    position: 'absolute',
    width: 380,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[ 5 ],
    padding: '10px 10px 0 10px',
  },
  description: {
    paddingBottom: '10px',
    paddingTop: '10px',
    fontSize: '16px'
  },
}));

export default function GenericModal({ isOpen, onClose, title, description }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={isOpen}
      onClose={onClose}
      onBackdropClick={onClose}
    >
      <Card style={modalStyle} className={classes.card}>
        <CardContent>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <Typography
            className={classes.description}
            variant="body2"
            component="p"
            color="textSecondary"
          >
            {description}
          </Typography>
          <Grid container alignItems="flex-start" justify="flex-end" direction="row">
            <Button
              classes={classes.closeButton}
              onClick={onClose}
              color="primary"
            >
              OK
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
}
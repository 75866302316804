import { FETCH_SPOT } from './spot.actions';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SPOTS = 'FETCH_USER_SPOTS';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const SET_NEW_PROFILE_IMAGE = 'SET_NEW_PROFILE_IMAGE';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';


export function loginAction(username, password) {
  return {
    type: LOGIN,
    payload: { username, password }
  };
}

export function setUserAction(user) {
  return {
    type: SET_USER,
    payload: user
  };
}

export function setUserProfileAction(authUser, profile) {
  return {
    type: SET_USER_PROFILE,
    payload: { authUser, profile }
  };
}

export function updateUserProfileAction(values) {
  return {
    type: UPDATE_USER_PROFILE,
    payload: values
  };
}

export function setNewProfileImageAction(photo) {
  return {
    type: SET_NEW_PROFILE_IMAGE,
    payload: { photo }
  };
}

export function logoutAction() {
  return {
    type: LOGOUT
  };
}

export function validateTokenAction() {
  return {
    type: VALIDATE_TOKEN
  };
}

export function setCurrentLocation(lat, lon) {
  return {
    type: SET_CURRENT_LOCATION,
    payload: { lat, lon }
  }
}

export function fetchUserAction(userId) {
  return {
    type: FETCH_USER,
    payload: userId
  };
}

export function fetchUserSpotsAction(userId) {
  return {
    type: FETCH_USER_SPOTS,
    payload: userId
  };
}

import { useCallback } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import * as actions from "../actions/spot.actions";
import {
  selectSpots,
  selectSelectedSpot,
  selectShowCreateSpotMarker,
  selectMapCenterLocation,
  selectCreateSpotLocation,
  selectSelectedSpotsNearby
} from '../selectors/spots.selectors';


export function useSpots() {
  return useMappedState(selectSpots);
}

export function useSelectedSpot() {
  return useMappedState(selectSelectedSpot);
}

export function useSelectedSpotsNearby() {
  return useMappedState(selectSelectedSpotsNearby);
}

export function useShowCreateSpotMarker() {
  return useMappedState(selectShowCreateSpotMarker);
}

export function useMapCenterLocation() {
  return useMappedState(selectMapCenterLocation);
}

export function useUserSelectedSpotLocation() {
  return useMappedState(selectCreateSpotLocation);
}

export function useFetchSpotRequest() {
  const dispatch = useDispatch();

  return useCallback(spotId => {
    dispatch(actions.fetchSpotAction(spotId));
  }, []);
}

export function useFetchSpotsRequest() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(actions.fetchSpotsAction());
  }, []);
}

export function useCreateSpotRequest() {
  const dispatch = useDispatch();

  return useCallback(values => {
    dispatch(actions.createSpotAction(values));
  }, []);
}

export function useSetShowCreateSpotMarkerRequest() {
  const dispatch = useDispatch();

  return useCallback(show => {
    dispatch(actions.setShowCreateSpotMarkerAction(show));
  }, []);
}

export function useSetCreateSpotMarkerLocationRequest(payload) {
  const dispatch = useDispatch();

  return useCallback((payload) => {
    dispatch(actions.setCreateSpotMarkerLocationAction(payload));
  }, []);
}

export function useSetMapCenterLocationRequest() {
  const dispatch = useDispatch();

  return useCallback(location => {
    dispatch(actions.setMapCenterLocationAction(location));
  }, []);
}

export function useAddSpotLocalRequest() {
  const dispatch = useDispatch();

  return useCallback((userId, spot) => {
    dispatch(actions.addSpotLocalAction(userId, spot));
  }, []);
}

export function useRemoveSpotLocalRequest() {
  const dispatch = useDispatch();

  return useCallback((userId, spot) => {
    dispatch(actions.removeSpotLocalAction(userId, spot));
  }, []);
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Rating from '@material-ui/lab/Rating';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { getImagePath, getSubHeader } from '../../utils';
import { Box } from '@material-ui/core';
import { getIconName, getSpotIcon } from '../icons';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    borderRadius: '8px',
    maxWidth: 291,
    margin: '8px',
  },
  ratingsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    bottom: '56px',
    position: 'absolute',
    right: '10px',
  }
});

const SpotPreviewCard = ({ spot }) => {
  const classes = useStyles();

  return (
    <NavLink to={`/spot-detail/${spot.spotId}`} style={{ textDecoration: 'none' }}>
      <Card className={classes.root} variant="outlined" elevation={0}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="160"
            image={spot && getImagePath(spot.photos[ 0 ])}
            title={spot.name}
          />
          <CardContent style={{ paddingTop: 10 }}>
            <Box className={classes.ratingsContainer}>
              <Rating name="size-small" defaultValue={4} size="small"/>
              {/* TODO complete image icon component from svg files */}
              <Box className={classes.iconContainer}>
                {getSpotIcon(spot)}
              </Box>
            </Box>
            <Typography align="left" style={{ fontSize: '15px' }}>
              {spot.name}
            </Typography>
            <Typography align="left" variant="body2" component="p" style={{ fontSize: '13px' }}>
              {getSubHeader(spot)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </NavLink>
  );
}

export default SpotPreviewCard;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BaseCard from './BaseCard';
import Typography from '@material-ui/core/Typography';
import ProfilePreviewCard from './ProfilePreviewCard';
import { Box } from '@material-ui/core';
import NoLocalsCard from './NoLocalsCard';
import { useAddSpotLocalRequest } from '../../flux/effects/spot.effects';
import { useUserProfile } from '../../flux/effects/profile.effects';

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  }
}));

const LocalsCard = ({ profiles, spot }) => {
  const classes = useStyles();
  const { userId } = useUserProfile();
  const addLocal = useAddSpotLocalRequest();

  const renderLocals = () => {
    if (profiles && profiles.length > 0) {
      return (
        <>
          {profiles.map(profile => <ProfilePreviewCard profile={profile} />)}
        </>
      )
    }

    return <NoLocalsCard onAdd={() => addLocal(userId, spot)} />
  }

  return (
    <BaseCard
      onSeeAll={() => console.log('See All')}
      title="Locals"
      contentStyles={{ paddingLeft: "8px", paddingRight: "8px" }}
    >
      <Box className={classes.profileContainer}>
        {renderLocals()}
      </Box>
    </BaseCard>
  );
};

export default LocalsCard;
import React from 'react';
import { Feature, Layer } from 'react-mapbox-gl';
import { useMapCenterLocation, useSetCreateSpotMarkerLocationRequest } from '../../flux/effects/spot.effects';

export default function CreateSpotMarker(props) {
  const mapCenterCoords = useMapCenterLocation();
  const setCreateSpotMarkerLocation = useSetCreateSpotMarkerLocationRequest();

  return (
    <Layer  type="symbol" layout={{ 'icon-image': 'place-marker' }}>
      <Feature
        coordinates={mapCenterCoords && [mapCenterCoords.lng, mapCenterCoords.lat]}
        draggable
        onDragEnd={event => setCreateSpotMarkerLocation(event.lngLat)}
      />
    </Layer>
  );
}

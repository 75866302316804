import React from 'react';
import ParkIcon from './ParkIcon';
import DitchIcon from './DitchIcon';
import DiyIcon from './DiyIcon';
import PoolIcon from './PoolIcon';
import StreetIcon from './StreetIcon';
import UnknownIcon from './UnknownIcon';
import RampIcon from './RampIcon';

const spotIconMap = {
  Ditch: <DitchIcon />,
  Diy: <DiyIcon />,
  Park: <ParkIcon />,
  Pool: <PoolIcon />,
  Ramp: <RampIcon />,
  Street: <StreetIcon />,
  Unknown: <UnknownIcon />,
}


export const getIconName = spot => spot.image.split("/")[1].replace(".png", "");

export const getSpotIcon = spot => {
  return spotIconMap[getIconName(spot)]
}
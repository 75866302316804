import { useCallback } from "react";
import { useMappedState, useDispatch } from "redux-react-hook";
import * as selectors from "../selectors/profile.selectors";
import * as actions from "../actions/user.actions";

export function useUserProfile() {
  return useMappedState(selectors.selectUserProfile);
}

export function useSelectedUserProfile() {
  return useMappedState(selectors.selectSelectedUserProfile);
}

export function useNewProfileImage() {
  return useMappedState(selectors.selectNewProfileImage);
}

export function useCurrentLocation() {
  return useMappedState(selectors.selectCurrentLocation);
}

export function useUpdateUserProfileRequest() {
  const dispatch = useDispatch();

  return useCallback(values => {
    dispatch(actions.updateUserProfileAction(values));
  }, []);
}

export function useSetNewProfileImageRequest() {
  const dispatch = useDispatch();

  return useCallback(photo => {
    dispatch(actions.setNewProfileImageAction(photo));
  }, []);
}

export function useSetCurrentLocationRequest() {
  const dispatch = useDispatch();

  return useCallback((lat, lon) => {
    dispatch(actions.setCurrentLocation(lat, lon));
  }, []);
}

export function useFetchUserRequest() {
  const dispatch = useDispatch();

  return useCallback(userId => {
    dispatch(actions.fetchUserAction(userId));
  }, []);
}

export function useFetchUserSpotsRequest() {
  const dispatch = useDispatch();

  return useCallback(userId => {
    dispatch(actions.fetchUserSpotsAction(userId));
  }, []);
}
import { IMAGE_ROOT_URL } from './app.config';
import dateformat from 'dateformat'

export function getFormattedDate(date) {
  return dateformat(date, "dd mmmm, yyyy");
}

export function metersToMiles(meters) {
  const miles = meters * 0.00062137;
  return miles.toFixed(2)
}

export function secondsToMinutes(seconds) {
  return Math.round(seconds / 60)
}

export function getCurrentLocation(successCallback) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(successCallback);
  }
}

export const getSubHeader = spot => {
  try {
    return `${spot.meta.townCity} ${spot.meta.countyState && spot.meta.townCity && ', '} ${spot.meta.countyState}`;
  } catch (e) {
    return '-'
  }
};

export const getImagePath = imageName => {
  return `${IMAGE_ROOT_URL}/${imageName}`
}

// extract townCity and countyState

export function extractTownAndCounty(data) {
  /*
  {
      "id": "address.1752791763583024",
      "type": "Feature",
      "place_type": [
        "address"
      ],
      "relevance": 1,
      "properties": {
        "accuracy": "point"
      },
      "text": "Chapel Fields",
      "place_name": "4 Chapel Fields, Biggleswade, SG18 0AL, United Kingdom",
      "center": [
        -0.265149,
        52.089662
      ],
      "geometry": {
        "type": "Point",
        "coordinates": [
          -0.265149,
          52.089662
        ]
      },
      "address": "4",
      "context": [
        {
          "id": "postcode.9408096251947490",
          "text": "SG18 0AL"
        },
        {
          "id": "place.15332848356677070",
          "wikidata": "Q2224167",
          "text": "Biggleswade"
        },
        {
          "id": "district.6972011902670050",
          "wikidata": "Q1053723",
          "text": "Central Bedfordshire"
        },
        {
          "id": "region.13483278848453920",
          "wikidata": "Q21",
          "short_code": "GB-ENG",
          "text": "England"
        },
        {
          "id": "country.12405201072814600",
          "wikidata": "Q145",
          "short_code": "gb",
          "text": "United Kingdom"
        }
      ]
    }
  */

  let town = "";
  let county = "";
  try {
    data["features"][0].context.forEach(item => {
      if(item.id.includes("place")) {
        town = item.text;
      }

      if(item.id.includes("district")) {
        county = item.text
      }
    });
  } catch (e) {
    console.error("Unable to extract town and city", e);
  }

  return {
    town,
    county
  };
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode) {
  if(!isoCode) {
    return "-"
  }

  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}
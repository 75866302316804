import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BaseCard from './BaseCard';
import Typography from '@material-ui/core/Typography';
import { useSelectedUserProfile } from '../../flux/effects/profile.effects';
import SpotPreviewCard from './SpotPreviewCard';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  spotContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  }
}));

const SpotsByCard = ({ spots }) => {
  const classes = useStyles();
  const user = useSelectedUserProfile();

  return (
    <BaseCard
      onSeeAll={() => console.log('See All')}
      title={`Spots by ${user.displayName}`}
    >
      <Box className={classes.spotContainer}>
        {spots && spots.map(spot => (
          <SpotPreviewCard spot={spot} />
        ))}
      </Box>
    </BaseCard>
  );
};

export default SpotsByCard;
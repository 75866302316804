import React, { useEffect } from 'react';
import { useDispatch } from "redux-react-hook";
import { fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import FlagIcon from '@material-ui/icons/Flag';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import InputBase from '@material-ui/core/InputBase';
import Hidden from '@material-ui/core/Hidden';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth0 } from "./react-auth0-spa";
import ProfileModal from './components/profile/ProfileModal';
import { setUserAction } from './flux/actions/user.actions';
import SpotCreateModalContainer from './components/spot/SpotCreateModalContainer';
import RequestLocationModal from './components/location/RequestLocationModal';
import NavbarMenu from './components/menus/NavbarMenu';
import { Avatar, Typography } from '@material-ui/core';
import { useUserProfile } from './flux/effects/profile.effects';


const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    bottom: 'auto',
    top: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    bottom: 65,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  profileBtn: {
    // marginRight: "8px",
  },
  signInBtn: {
    fontSize: "15px"
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#546269',
    '&:hover': {
      backgroundColor: '#546269',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    position: 'absolute',
    zIndex: 9999,
    top: 18,
    left: 0,
    right: 0,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      zIndex: 0,
    },
  },
  searchIconRoot: {
    position: 'absolute',
    zIndex: 9999,
    top: 21,
    left: 100,
    right: 0,
    color: 'white',
    // margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      top: 17,
      left: 92,
    },
  },
  searchBoxContainer: {
    // display: 'flex'
  }
}));


export default function BottomAppBar({ hideAddSpot, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isAuthenticated, loginWithRedirect, getTokenSilently, getIdTokenClaims, logout, user } = useAuth0();

  useEffect(() => {
    dispatch(setUserAction(user))
  }, [user])

  const profile = useUserProfile()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accessToken, setAccessToken] = React.useState();
  const [profileModalOpen, setProfileModalOpen] = React.useState(false);
  const [spotModalOpen, setSpotModalOpen] = React.useState(false);
  const [locationModalOpen, setLocationModalOpen] = React.useState(false);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.searchBoxContainer}>
          <div id="searchBox" className={classes.search}>
            <div id="searchBoxIcon" className={classes.searchIcon}>
              {/*<SearchIcon />*/}
            </div>
            {/*<InputBase*/}
            {/*  placeholder="Search…"*/}
            {/*  classes={{*/}
            {/*    root: classes.inputRoot,*/}
            {/*    input: classes.inputInput,*/}
            {/*  }}*/}
            {/*  inputProps={{ 'aria-label': 'search' }}*/}
            {/*/>*/}
          </div>
        </div>
        <div className={classes.grow} />
        {isAuthenticated ? (
          <React.Fragment>
            <Hidden xsDown>
              <IconButton aria-label="show 17 new notifications" color="inherit">
                <Badge color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Hidden>
            <IconButton
              edge="end"
              color="inherit"
              className={classes.profileBtn}
              onClick={handleProfileMenuOpen}
              // onClick={() => {
              //   getTokenSilently({audience: "https://skatespotter/api"}).then(token => {
              //     setAccessToken(token)
              //     console.log('token', token);
              //   })
              // }}
            >
              {/*<ProfileIcon/>*/}
              <Hidden xsDown>
                <Avatar src={profile && profile.photo} />
              </Hidden>
              <Hidden smUp>
                <MoreVertIcon />
              </Hidden>
            </IconButton>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Hidden xsDown>
              <Button
                color="inherit"
                onClick={() => {
                  loginWithRedirect().then(data => console.log('data', data))
                  // setAccessToken(getTokenSilently())
                  console.log('accessToken', accessToken);
                }}
              >
                SIGN IN
              </Button>
            </Hidden>
            <Hidden smUp>
              <IconButton
                edge="end"
                color="inherit"
                className={classes.profileBtn}
                onClick={() => {
                  loginWithRedirect().then(data => console.log('data', data))
                  // setAccessToken(getTokenSilently())
                  console.log('accessToken', accessToken);
                }}
              >
              <ProfileIcon/>
              </IconButton>
            </Hidden>
          </React.Fragment>
        )}
      </Toolbar>
      <NavbarMenu
        menuId="primary-search-account-menu"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        user={user}
        onLogout={logout}
        onMenuClose={handleMenuClose}
        onProfileOpen={() => setProfileModalOpen(true)}
      />
    </AppBar>
    <ProfileModal
      profileModalOpen={profileModalOpen}
      setProfileModalOpen={setProfileModalOpen}
      userImage={user && user.picture}
      userName={user && user.name}
    />
    <SpotCreateModalContainer spotModalOpen={spotModalOpen} setSpotModalOpen={setSpotModalOpen} />
    <RequestLocationModal
      modalOpen={locationModalOpen}
      setModalOpen={setLocationModalOpen}
      onSuccess={() => setSpotModalOpen(true)}
    />
      {/*<SearchIcon className={classes.searchIconRoot} />*/}
    <img src="/skatespotter-logo-s.svg" className={classes.logoContainer} />

      {!hideAddSpot &&
      <Fab color="secondary" aria-label="add" className={classes.fabButton}>
        <FlagIcon color="primary" onClick={() => {
          if (!isAuthenticated) {
            setSpotModalOpen(true)
          } else {
            setLocationModalOpen(true)
          }
        }}/>
      </Fab>
      }
    </React.Fragment>
  );
}
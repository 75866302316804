import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "./react-auth0-spa";
import { createBrowserHistory } from "history";
import config from "./auth_config.json";
import { skateSpotterTheme } from './skateSpotterTheme';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { StoreContext } from "redux-react-hook";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import rootSaga from './flux/sagas';
import rootReducer from './flux/reducers';


const loggerConfig = {
  collapsed: true
};

const setupDevTools = () => {
  return (
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
};

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const createStoreWithMiddleware = applyMiddleware(
  routerMiddleware(history),
  sagaMiddleware,
  createLogger(loggerConfig),
)(createStore);

const store = createStoreWithMiddleware(
  connectRouter(history)(rootReducer),
  setupDevTools()
);

sagaMiddleware.run(rootSaga);


const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : "/" // window.location.pathname
  );
};

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      // audience={config.audience}
      onRedirectCallback={onRedirectCallback}
    >
      <StoreContext.Provider value={store}>
        <ThemeProvider theme={skateSpotterTheme}>
          <Router history={history}>
            <App/>
          </Router>
        </ThemeProvider>
      </StoreContext.Provider>
    </Auth0Provider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSetCurrentLocationRequest, useUserProfile } from '../../flux/effects/profile.effects';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { getCurrentLocation } from '../../utils';
import { useSetShowCreateSpotMarkerRequest } from '../../flux/effects/spot.effects';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '366px',
    height: '192px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[ 5 ],
    padding: "8px",
    paddingBottom: 0,
    borderRadius: '4px',
    "&:focus": {
      outline: 'none'
    },
  },
  modalTitle: {
    fontSize: '20px',
    paddingLeft: '24px',
    marginBottom: '16px',
  },
  modalDescription: {
    fontSize: '16px',
    paddingLeft: '24px',
    paddingRight: '24px',
    marginTop: '8px',
  },
  large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  avatarBadge: {
    color: "white"
  },
  closeContainer: {
    width: '100%',
  },
  badgeRoot: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(8),
  },
  formRoot: {
    marginBottom: theme.spacing(12),
  },
  iconCloseButton: {
    backgroundColor: "rgb(84, 83, 83, 0.7)",
    "&:hover": {
      backgroundColor: "rgb(84, 83, 83)"
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    // width: '72px',
    height: '36px',
    alignSelf: 'center',
    marginTop: '30px',
    marginRight: '16px',
  },
  closeBtn: {
    // width: '72px',
    height: '36px',
    alignSelf: 'center',
    marginTop: '30px',
    marginRight: '8px',
  },
}));


export default function RequestLocationModal({ modalOpen, setModalOpen, onSuccess }) {
  const classes = useStyles();
  const setCurrentLocation = useSetCurrentLocationRequest();
  const setShowCreateSpotMarker = useSetShowCreateSpotMarkerRequest();

  const locationRequestSuccess = position => {
    setCurrentLocation(position.coords.latitude, position.coords.longitude);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={modalOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalOpen}>
        <div className={classes.paper}>
          <Typography component="h4" className={classes.modalTitle}>
            Use your current location?
          </Typography>
          <Typography className={classes.modalDescription} variant="body2" color="textSecondary" component="p">
            Use your GPS location to add spots or drag markers to anywhere on the map.
          </Typography>
          <div className={classes.btnContainer}>
            <Button
              className={classes.actionBtn}
              onClick={() => {
                getCurrentLocation(locationRequestSuccess);
                onSuccess();
                setModalOpen(false);
              }}
            >
              YEP, AT THE SPOT
            </Button>
            <Button
              className={classes.closeBtn}
              onClick={() => {
                setCurrentLocation(null, null);
                setModalOpen(false);
                setShowCreateSpotMarker(true);
              }}
            >
              NO THANKS
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

import * as React from "react";

function DiyIcon(props) {
  return (
    <svg
      width="2.5em"
      height="2.5em"
      viewBox="0 0 38 42"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <filter
          x="-18.8%"
          y="-75%"
          width="137.5%"
          height="250%"
          filterUnits="objectBoundingBox"
          id="Diy_svg__a"
        >
          <feGaussianBlur stdDeviation={1} in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <ellipse
          fill="#000"
          opacity={0.3}
          filter="url(#Diy_svg__a)"
          cx={20}
          cy={39}
          rx={8}
          ry={2}
        />
        <rect
          stroke="#000"
          fill="#F44336"
          transform="rotate(45 19.799 19.799)"
          x={6.299}
          y={6.299}
          width={27}
          height={27}
          rx={3}
        />
        <path
          d="M16.443 23.887c-.245-.49-.168-1.23.182-1.668L20 18l3.375 4.219c.345.431.421 1.189.182 1.668L20 31l-3.557-7.113z"
          fill="#000"
        />
        <path fill="#000" d="M19 15h2v6h-2z" />
        <rect fill="#000" x={18} y={9} width={4} height={8} rx={2} />
      </g>
    </svg>
  );
}

export default DiyIcon;


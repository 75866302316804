import React from 'react';
import { Route, Switch } from "react-router-dom";
import MapBoxMap from './MapBoxMap';
import './App.scss';
import { Helmet } from 'react-helmet';
import SpotDetailPage from './pages/SpotDetailPage';
import ProfileDetailPage from './pages/ProfileDetailPage';


export default function App() {
  return (
    <div className="App">
      <Helmet>
        <meta name="title" content="Skate Spotter | International skate spot guide" />
        <meta name="description" content="Search our growing database for places to go skateboarding anywhere in the world. New spots added weekly." />
      </Helmet>
      <Switch>
        <Route path="/profile-detail/:userId" component={ProfileDetailPage}/>
        <Route path="/spot-detail/:spotId" component={SpotDetailPage}/>
        <Route path="/:spot" component={MapBoxMap}/>
        <Route path="/" component={MapBoxMap}/>
      </Switch>
    </div>
  );
}



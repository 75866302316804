import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Card } from '@material-ui/core';
import SeeAllButton from '../buttons/SeeAllButton';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 18,
  },
  pos: {
    marginBottom: 12,
  },
  cardHeader: {
    paddingBottom: 0
  }
}));

const BaseCard = ({ onSeeAll, title, contentStyles, rootStyles, ...props }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} style={{ ...rootStyles }} variant="outlined" elevation={0}>
      <CardHeader
        title={title}
        action={<SeeAllButton onClick={onSeeAll} />}
        titleTypographyProps={{ align: 'left', variant: 'h6'}}
        className={classes.cardHeader}
      />
      <CardContent style={{ ...contentStyles }}>
        {props.children}
      </CardContent>
    </Card>
  );
};

export default BaseCard;
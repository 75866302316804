import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';
import { Avatar, Card } from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/Directions';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import SpotStatsPanel from '../spot/SpotStatsPanel';
import { getFormattedDate, getSubHeader } from '../../utils';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { useSelectedUserProfile, useUserProfile } from '../../flux/effects/profile.effects';
import { NavLink } from 'react-router-dom';
import SpotLocalButton from '../buttons/SpotLocalButton';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    width: '100%',
    height: '752px'
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardContent: {
    paddingTop: 0,
  },
  actionBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(3),
  },
  localBtn: {
    color: 'black',
    marginRight: theme.spacing(2),
  },
  directionsButton: {
    borderWidth: '1px',
    borderColor: '#c8c8c8',
    // flexGrow: 1
  },
  ratingsContainer: {
    textAlign: 'left',
    marginLeft: 0,
    paddingLeft: 0,
  },
}));

const SpotDetailCard = ({ spot }) => {
  const classes = useStyles();
  const spotCreator = useSelectedUserProfile();
  const user = useUserProfile();

  return (
    <Card className={classes.root} variant="outlined" elevation={0}>
      <CardHeader
        className={classes.cardHeader}
        action={
          <IconButton aria-label="settings" size="small">
            <MoreVertIcon color="primary" />
          </IconButton>
        }
      />
      <CardContent
        className={classes.cardContent}
      >
        <Typography className={classes.title} variant="h4" align="left" gutterBottom>
          {spot && spot.name}
        </Typography>
        <Typography variant="body1" align="left" component="p">
          {spot && getSubHeader(spot)}
        </Typography>

        <div className={classes.actionBtnContainer}>
          <SpotLocalButton userId={user.userId} spot={spot}/>
          <Button
            className={classes.directionsButton}
            size="medium"
            color="primary"
            variant="outlined"
          >
            <DirectionsIcon/> Directions
          </Button>
        </div>

        <Box mt={3} mb={0}>
        <Typography variant="body2" color="textSecondary" align="left" component="p">
          Rating for this spot
        </Typography>

          <Box
            ml={0}
            pl={0}
            className={classes.ratingsContainer}
            component="fieldset"
            borderColor="transparent"
          >
            <Rating
              name="simple-controlled"
              value={4}
              // onChange={(event, newValue) => {
              //   setValue(newValue);
              // }}
            />

            <span>3.6 (12 reviews)</span>

            <Box mt={0.5}>
              <Link>
                <Typography variant="body2" color="textSecondary" align="left" component="p">
                  Rate this spot
                </Typography>
              </Link>
            </Box>

          </Box>

        </Box>

        <Divider />

        <SpotStatsPanel />

        <Divider />

        <Box mt={2} mb={3}>
          <ListItem style={{ paddingLeft: 0, paddingBottom: 0 }}>
            <ListItemAvatar>
              <NavLink to={`/profile-detail/${spotCreator.userId}`}>
                <Avatar src={spotCreator.photo} variant="rounded"/>
              </NavLink>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography

                  style={{ fontSize: '16px' }}
                >
                  Added by {spotCreator.displayName || 'Unknown'}
                </Typography>
              }
              secondary={
                <Typography
                  color="textSecondary"
                  style={{ fontSize: '16px', fontWeight: '500' }}
                >
                  {getFormattedDate(spot.created)}
                </Typography>
              }
            />
          </ListItem>
        </Box>

        <Box mb={2} mt={2}>
          <Typography align="left">
            {spot.description}
          </Typography>
        </Box>

        <Box>
          <Typography align="left">
            Opening times:
            <Button
              color="secondary"
              style={{ textTransform: 'none'}}
            >
              Set an opening time
            </Button>
          </Typography>
        </Box>

        <Box>
          <Typography align="left">
            Weather:
            <Button
              color="secondary"
              style={{ textTransform: 'none'}}
            >
              Check the forecast
            </Button>
          </Typography>
        </Box>

      </CardContent>
    </Card>
  );
};

export default SpotDetailCard;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BaseCard from './BaseCard';
import SpotPreviewCard from './SpotPreviewCard';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  spotContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  }
}));

const SpotsNearbyCard = ({ spots }) => {
  const classes = useStyles();

  return (
    <BaseCard
      onSeeAll={() => console.log('See All')}
      title="Sports nearby"
      contentStyles={{ paddingLeft: "8px", paddingRight: "8px" }}
    >
      <Box className={classes.spotContainer}>
        {spots && spots.map(spot => (
          <SpotPreviewCard spot={spot} />
        ))}
      </Box>
    </BaseCard>
  );
};

export default SpotsNearbyCard;
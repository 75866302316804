import React, { useState } from 'react';
import { Formik } from 'formik';
import { useUserProfile } from '../../flux/effects/profile.effects';
import ProfileForm from './ProfileForm';
import { useUpdateUserProfileRequest } from '../../flux/effects/profile.effects';

const DEFAULT_VALUES = { displayName: '' }

const ProfileFormContainer = ({ photo, onClose, onSuccess, ...props}) => {
  const userProfile = useUserProfile();
  const updateUserProfile = useUpdateUserProfileRequest();
  const getPhoto = () => {
    return photo
  }

  return (
    <Formik
      render={props =>
        <ProfileForm
          onClose={onClose}
          {...props}
        />}
      initialValues={userProfile? { ...userProfile } : DEFAULT_VALUES}
      onSubmit={(values, { resetForm }) => {
        updateUserProfile({ ...values, photo: getPhoto() })
        resetForm();
        onSuccess();
      }}
    />
  );
};

export default ProfileFormContainer;

import { combineReducers } from 'redux';
import spotsReducer from './spots.reducer';
import userReducer from './user.reducer';


const rootReducer = combineReducers({
  user: userReducer,
  spots: spotsReducer,
});

export default rootReducer;

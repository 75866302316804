import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'react-router-dom'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import BottomAppBar from '../BottomAppBar';
import SpotDetailCard from '../components/cards/SpotDetailCard';
import SpotGalleryCard from '../components/cards/SpotGalleryCard';
import LocalsCard from '../components/cards/LocalsCard';
import SpotsNearbyCard from '../components/cards/SpotsNearbyCard';
import { useFetchSpotRequest, useSelectedSpot, useSelectedSpotsNearby } from '../flux/effects/spot.effects';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
    paddingTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  pageContentContainer: {
    padding: theme.spacing(10, 12, 12, 12),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10, 1, 12, 1),
    },
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const SpotDetailPage = (props) => {
  const classes = useStyles();
  const spotId = props.match.params.spotId;
  const fetchSpot = useFetchSpotRequest();
  const spot = useSelectedSpot();
  const spotsNearBy = useSelectedSpotsNearby();

  useEffect(() => {
    fetchSpot(spotId)
  }, [spotId])

  return (
    <React.Fragment>
      <BottomAppBar hideAddSpot />
      <div className={classes.pageContentContainer}>

        <Breadcrumbs aria-label="home">
          <NavLink color="inherit" to="/">
            Map
          </NavLink>
          <Typography color="textPrimary">Spot</Typography>
        </Breadcrumbs>

        <div className={classes.gridRoot}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={5}>
              <SpotDetailCard spot={spot} />
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <SpotGalleryCard />
            </Grid>

            <Grid item xs={12}>
              <LocalsCard profiles={spot.spotLocalsDetails} spot={spot}/>
            </Grid>

            <Grid item xs={12}>
              <SpotsNearbyCard spots={spotsNearBy} />
            </Grid>

          </Grid>
        </div>

      </div>

    </React.Fragment>
  )
}

export default SpotDetailPage;

import * as React from "react";

function UnknownIcon(props) {
  return (
    <svg
      width="2.5em"
      height="2.5em"
      viewBox="0 0 38 42"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <filter
          x="-18.8%"
          y="-75%"
          width="137.5%"
          height="250%"
          filterUnits="objectBoundingBox"
          id="Unkown_svg__a"
        >
          <feGaussianBlur stdDeviation={1} in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <ellipse
          fill="#000"
          opacity={0.3}
          filter="url(#Unkown_svg__a)"
          cx={20}
          cy={39}
          rx={8}
          ry={2}
        />
        <rect
          stroke="#000"
          fill="#FF9800"
          transform="rotate(45 19.799 19.799)"
          x={6.299}
          y={6.299}
          width={27}
          height={27}
          rx={3}
        />
        <path
          d="M16.247 15.659c.416-.364 1.204-.659 1.744-.659h4.018c.547 0 1.335.3 1.744.659l5.742 5.024c.831.728.615 1.317-.486 1.317H10.99c-1.1 0-1.323-.585-.486-1.317l5.742-5.024z"
          fill="#000"
        />
      </g>
    </svg>
  );
}

export default UnknownIcon;


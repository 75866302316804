import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BaseCard from './BaseCard';
import Typography from '@material-ui/core/Typography';
import { useSelectedUserProfile } from '../../flux/effects/profile.effects';

const useStyles = makeStyles((theme) => ({
}));

const SkatesWithCard = (props) => {
  const classes = useStyles();
  const user = useSelectedUserProfile();

  return (
    <BaseCard
      onSeeAll={() => console.log('See All')}
      title={`${user.displayName} skates with`}
    >
      <Typography color="textPrimary">Coming soon</Typography>
    </BaseCard>
  );
};

export default SkatesWithCard;
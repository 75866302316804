import React, { useState } from 'react';
import { Formik } from 'formik';
import CreateSpotForm from './CreateSpotForm';
import validationSchema from './validationSchema';
import { useUserProfile, useCurrentLocation } from '../../../flux/effects/profile.effects';
import {
  useCreateSpotRequest,
  useMapCenterLocation,
  useUserSelectedSpotLocation
} from '../../../flux/effects/spot.effects';
import useWindowSize from '../../../flux/effects/window.effects';
import { MOBILE_WIDTH } from '../../../app.config';

const CreateSpotFormContainer = ({ onClose, onSuccess, ...props}) => {
  const createSpot = useCreateSpotRequest();
  const userProfile = useUserProfile();
  const currentLocation = useCurrentLocation();
  const currentMapCenter = useMapCenterLocation();
  const userSelectedLocation = useUserSelectedSpotLocation();
  const [photo, setPhoto] = useState();

  const size = useWindowSize();
  const isMobile = size.width <= MOBILE_WIDTH;

  const getSpotLocation = () => {
    // we are on mobile and the user is selecting the location based on map center
    if(isMobile && currentMapCenter.lat) {
      return {
        lat: currentMapCenter.lat,
        lon: currentMapCenter.lng
      };
    }

    // we are on mobile but using current location
    if(isMobile && currentLocation.lat) {
      return currentLocation;
    }

    // else work it out based on selected location or current location as a fall back
    return userSelectedLocation.lat && userSelectedLocation.lon ? userSelectedLocation: currentLocation;
  }


  return (
    <Formik
      render={props =>
        <CreateSpotForm
          photo={photo}
          setPhoto={setPhoto}
          onClose={onClose}
          {...props}
        />}
      initialValues={{ name: '', description: '', image: '' }}
      onSubmit={(values, { resetForm }) => {
        const imagePaths = photo.split('/')
        createSpot({
          ...values,
          ...getSpotLocation(),
          creatorId: userProfile.userId,
          photos: [imagePaths[imagePaths.length - 1]],
        })
        resetForm();
        onSuccess();
      }}
      validationSchema={validationSchema}
    />
  );
};

export default CreateSpotFormContainer;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { metersToMiles, secondsToMinutes } from './utils';
import RouteStep from './RouteStep';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  stepContainer: {
    maxHeight: '60vh',
    overflow: 'auto',
  },
}));


export default function RouteStepsContainer({ route, ...props }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ListItem>
        <ListItemText
          primary={`${secondsToMinutes(route.duration)} mins (${metersToMiles(route.distance)} miles)`}
          secondary={`via ${route.legs[0].summary}`}
        />
      </ListItem>
      <Divider />
      <List className={classes.stepContainer}>
        {route.legs[0].steps.map((step, index) => <RouteStep step={step} key={`${index}_${step.name}`}/>)}
      </List>
    </div>
  );
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'bottom',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: '25px',
  },
  closeBtn: {
    marginRight: theme.spacing(2),
    minWidth: '90px',
  },
  saveBtn: {
    boxShadow: 'none',
    color: 'black',
    minWidth: '90px',
  }
}));

export default function ProfileFormButtons({ onClose, onSave }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        className={classes.closeBtn}
        onClick={onClose}
      >
        CLOSE
      </Button>
      <Button
        variant="contained"
        color="secondary"
        className={classes.saveBtn}
        onClick={onSave}
      >
        UPDATE
      </Button>
    </div>
  );
}

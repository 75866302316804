import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FilmIcon from '@material-ui/icons/CameraRoll';
import ListItemText from '@material-ui/core/ListItemText';
import GroupIcon from '@material-ui/icons/Group';
import FlagIcon from '@material-ui/icons/Flag';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 0,
  },
  avatar: {
    minWidth: '35px'
  }
}));

const ProfileStatsPanel = (props) => {
  const classes = useStyles();

  return (
    <List className={classes.listContainer}>
      <ListItem style={{ paddingLeft: 0, width: '104px' }}>
        <ListItemAvatar className={classes.avatar}>
          <FlagIcon />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              color="textSecondary"
              style={{ fontSize: '11px' }}
            >
              SPOTS
            </Typography>
          }
          secondary={
            <Typography
              style={{ fontSize: '18px', lineHeight: '0.8', fontWeight: '500' }}
            >
              0
            </Typography>
          }
        />
      </ListItem>
      <ListItem style={{ paddingLeft: 0, width: '104px' }}>
        <ListItemAvatar className={classes.avatar}>
          <FilmIcon />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              color="textSecondary"
              style={{ fontSize: '11px' }}
            >
              FOOTAGE
            </Typography>
          }
          secondary={
            <Typography
              style={{ fontSize: '18px', lineHeight: '0.8', fontWeight: '500' }}
            >
              0
            </Typography>
          }
        />
      </ListItem>
      <ListItem style={{ paddingLeft: 0, width: '104px' }}>
        <ListItemAvatar className={classes.avatar}>
          <GroupIcon />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              color="textSecondary"
              style={{ fontSize: '11px' }}
            >
              FOLLOWERS
            </Typography>
          }
          secondary={
            <Typography
              style={{ fontSize: '18px', lineHeight: '0.8', fontWeight: '500' }}
            >
              0
            </Typography>
          }
        />
      </ListItem>
    </List>
  )
};

export default ProfileStatsPanel;
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textField: {
    // marginLeft: theme.spacing.unit,
    // marginTop: theme.spacing.unit,
    marginBottom: '12px',
    width: 388,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 285,
    }
    // marginRight: theme.spacing.unit * 2
  },
}));

function StandardTextInput({ name, value, label, errors, handleChange, touched, ...props }) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.textField}
      id={`name-${name}`}
      name={name}
      helperText={touched[name] ? errors[name] : ""}
      error={touched[name] && Boolean(errors[name])}
      label={label}
      onChange={handleChange}
      margin="normal"
      value={value}
      variant="outlined"
      fullWidth
      { ...props }
    />
  );
}

export default StandardTextInput;
import * as React from "react";

function PoolIcon(props) {
  return (
    <svg
      width="2.5em"
      height="2.5em"
      viewBox="0 0 38 42"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <filter
          x="-18.8%"
          y="-75%"
          width="137.5%"
          height="250%"
          filterUnits="objectBoundingBox"
          id="Pool_svg__a"
        >
          <feGaussianBlur stdDeviation={1} in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <ellipse
          fill="#000"
          opacity={0.3}
          filter="url(#Pool_svg__a)"
          cx={20}
          cy={39}
          rx={8}
          ry={2}
        />
        <rect
          stroke="#000"
          fill="#8BC34A"
          transform="rotate(45 19.799 19.799)"
          x={6.299}
          y={6.299}
          width={27}
          height={27}
          rx={3}
        />
        <circle fill="#000" cx={22} cy={18} r={6} />
        <circle fill="#000" cx={16} cy={22} r={4} />
      </g>
    </svg>
  );
}

export default PoolIcon;

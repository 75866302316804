import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DirectionsIcon from '@material-ui/icons/Directions';
import IconButton from '@material-ui/core/IconButton';
import { getRoute } from './directionsService';
import RouteStepsContainer from './RouteStepsContainer';
import { IMAGE_ROOT_URL } from './app.config';
import { NavLink } from 'react-router-dom';
import { getSubHeader } from './utils';

const useStyles = makeStyles(theme => ({
  card: {
    width: '398px',
    maxWidth: '398px',
    backgroundColor: 'white',
    position: 'absolute',
    top: 70,
    left: 10,
    [ '@media (max-width:445px)' ]: {
      width: '95vw',
    },
    zIndex: 2
  },
  routeCard: {
    width: '398px',
    maxWidth: '398px',
    backgroundColor: 'white',
    position: 'absolute',
    top: 70,
    left: 10,
    height: '85vh',
    [ '@media (max-width:445px)' ]: {
      width: '95vw',
    },
    zIndex: 2
  },
  cardSubContent: {
    backgroundColor: '#5187ED',
    color: 'white',
    fontWeight: '900',
  },
  media: {
    backgroundColor: '#D8D8D8',
    backgroundSize: 'auto',
    height: 220,
  },
  closeButton: {
    margin: theme.spacing(1),
    position: 'absolute',
    top: 62,
    left: 372,
    flex: 1,
    paddingRight: '0',
    [ '@media (max-width:445px)' ]: {
      visibility: 'hidden'
    },
  },
  iconCloseButton: {
    backgroundColor: "rgb(84, 83, 83, 0.7)",
    "&:hover": {
      backgroundColor: "rgb(84, 83, 83)"
    },
    color: 'white',
    float: 'right',
    marginTop: '5px',
    marginRight: '5px',
  },
  iconCloseButtonSecondary: {
    backgroundColor: "#5187ED",
    "&:hover": {
      backgroundColor: "#4f74d8"
    },
    color: "white",
    float: 'right',
  },
  closeButtonMobile: {
    visibility: 'hidden',
    margin: theme.spacing(1),
    position: 'absolute',
    top: 442,
    left: 2,
    flex: 1,
    minWidth: 0,
    width: 20,
    [ '@media (max-width:445px)' ]: {
      visibility: 'visible'
    },
  },
  routeContentContainer: {
    paddingTop: 0,
  },
  directionsButton: {
    borderWidth: '1px',
    borderColor: '#c8c8c8',
    flexGrow: 1
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px'
  },
  townCityText: {
    marginBottom: 15,
  },
  buttonProgress: {
    // color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  headerTypo: {
    color: 'white',
    fontWeight: '600',
  },
  subheaderTypo: {
    color: 'white',
  },
  visitPageBtn: {
    boxShadow: 'none',
    color: 'black',
    flexGrow: 1,
    '&:hover': {
      boxShadow: 'none'
    },
    // minWidth: '90px',
  }
}));

export default function SpotDetailCard({ onClose, spot, onDirections, resetDirections, ...props }) {
  const classes = useStyles();

  // const [directionsLoading, setDirectionsLoading] = useState(false);
  const [route, setRoute] = useState();

  useEffect(() => {
    resetDirections(null);
    setRoute(null);
  }, [spot.id])

  // const getCurrentLocation = () => {
  //   navigator.geolocation.getCurrentPosition(location => {
  //     return [location.coords.latitude, location.coords.longitude];
  //   });
  // }

  const setDirections = async (currentLocation) => {
    // setDirectionsLoading(true);
    const directions = await getRoute(
      'driving',
      currentLocation,
      [spot.lon, spot.lat]
    );

    // setDirectionsLoading(false);
    setRoute(directions.data.routes[ 0 ])
    return directions;
  };

  return (
    <React.Fragment>

      {spot &&
        <Helmet>
          <meta name="title" content={`${spot.name} in ${getSubHeader(spot)} | Skate Spotter`}/>
          <meta name="description" content={`Checkout ${spot.name} ${spot.title} in ${spot.meta.townCity}. Skate Spotter has other spots in ${spot.meta.countyState}. Use our map to get you there.`}/>
        </Helmet>
      }
      {!route ? (
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={spot.photos ? IMAGE_ROOT_URL + "/" + spot.photos[0] : "no-image.png"}
              title={spot && spot.name}
            >
              <IconButton
                className={classes.iconCloseButton}
                aria-label="close"
                onClick={onClose}
              >
                <CloseIcon/>
              </IconButton>
            </CardMedia>

            <CardContent>
              <Typography variant="h5" component="h2">
                {spot && spot.name}
              </Typography>
              <Typography
                className={classes.townCityText}
                gutterBottom
                variant="body2"
                component="p"
              >
                {getSubHeader(spot)}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {spot && spot.description}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions className={classes.footerContainer}>
            <Button
              className={classes.directionsButton}
              size="medium"
              color="primary"
              variant="outlined"
              onClick={() => {
                navigator.geolocation.getCurrentPosition(location => {
                  onDirections(setDirections([location.coords.longitude, location.coords.latitude]));
                });

              }}
            >
              <DirectionsIcon/> Directions
              {/*{directionsLoading && <CircularProgress size={24} className={classes.buttonProgress} />}*/}
            </Button>
            <Button
              component={NavLink}
              to={`/spot-detail/${spot.spotId}`}
              variant="contained"
              color="secondary"
              className={classes.visitPageBtn}
            >
              VISIT PAGE
            </Button>
          </CardActions>
        </Card>
      ) : (
        <Card className={classes.routeCard}>
          <CardHeader
            className={classes.cardSubContent}
            title={spot && spot.name}
            subheader={getSubHeader(spot)}
            titleTypographyProps={{ variant:"h5", component:"h2", className: classes.headerTypo }}
            subheaderTypographyProps={
              { variant: "body2", component: "p", className: classes.subheaderTypo }
            }
            action={<IconButton
                      className={classes.iconCloseButtonSecondary}
                      aria-label="close"
                      onClick={() => setRoute(null)}
                    >
                      <CloseIcon/>
                    </IconButton>
            }
          />
          <CardContent className={classes.routeContentContainer}>
            <RouteStepsContainer route={route}/>
          </CardContent>
        </Card>
      )
      }
    </React.Fragment>
  );
}

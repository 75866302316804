export const CREATE_SPOT = 'CREATE_SPOT';
export const UPDATE_SPOT = 'UPDATE_SPOT';
export const FETCH_SPOT = 'FETCH_SPOT';
export const FETCH_SPOTS = 'FETCH_SPOTS';
export const ADD_SPOT_LOCAL = 'ADD_SPOT_LOCAL';
export const REMOVE_SPOT_LOCAL = 'REMOVE_SPOT_LOCAL';
export const FETCH_NEAR_BY_SPOTS = 'FETCH_NEAR_BY_SPOTS';
export const FETCH_SPOT_LOCALS = 'FETCH_SPOT_LOCALS';
export const SET_MAP_CENTER_LOCATION = 'SET_MAP_CENTER_LOCATION';
export const SET_SHOW_CREATE_SPOT_MARKER = 'SET_SHOW_CREATE_SPOT_MARKER';
export const SET_CREATE_SPOT_MARKER_LOCATION = 'SET_CREATE_SPOT_MARKER_LOCATION';

export function createSpotAction(values) {
  return {
    type: CREATE_SPOT,
    payload: values
  };
}

export function updateSpotAction(spotId, values) {
  return {
    type: UPDATE_SPOT,
    payload: { spotId, values }
  };
}

export function fetchSpotAction(spotId) {
  return {
    type: FETCH_SPOT,
    payload: spotId
  };
}

export function fetchSpotsAction() {
  return {
    type: FETCH_SPOTS
  };
}

export function fetchSpotsNearByAction(spotId) {
  return {
    type: FETCH_NEAR_BY_SPOTS,
    payload: spotId
  };
}

export function setShowCreateSpotMarkerAction(show) {
  return {
    type: SET_SHOW_CREATE_SPOT_MARKER,
    payload: show
  };
}

export function setCreateSpotMarkerLocationAction(location) {
  return {
    type: SET_CREATE_SPOT_MARKER_LOCATION,
    payload: location
  };
}

export function setMapCenterLocationAction(location) {
  return {
    type: SET_MAP_CENTER_LOCATION,
    payload: location
  };
}

export function addSpotLocalAction(userId, spot) {
  return {
    type: ADD_SPOT_LOCAL,
    payload: { userId, spot }
  };
}

export function removeSpotLocalAction(userId, spot) {
  return {
    type: REMOVE_SPOT_LOCAL,
    payload: { userId, spot }
  };
}

import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import CreateSpotImageSelector from '../../spot/CreateSpotImageSelector';
import { SPOT_TYPES } from '../../../config';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  submitButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    margin: '10px 16px 16px 10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    marginBottom: '12px',
    marginRight: theme.spacing.unit
  },
  selectField: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: '12px',
    width: "100%",
  },
  cancelBtn: {
    width: '104px',
    height: '36px',
    alignSelf: 'flex-start',
    // marginTop: '30px',
  },
  submitBtn: {
    width: '104px',
    height: '36px',
    alignSelf: 'flex-start',
    marginLeft: "10px",
    boxShadow: 'none',
    color: 'rgba(0, 0, 0, 0.87)'
  },
}));

const CreateSpotForm = props => {
  const {
    values: { name, description, image },
    errors,
    touched,
    handleChange,
    handleSubmit,
    isValid,
    onSuccess,
    onClose,
    photo,
    setPhoto,
  } = props;
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} className={classes.container}>

      <CreateSpotImageSelector photo={photo} setPhoto={setPhoto}/>

      <TextField
        className={classes.textField}
        id="name-input"
        name="name"
        helperText={touched.name ? errors.name : ""}
        error={touched.name && Boolean(errors.name)}
        label="Spot name"
        onChange={handleChange}
        margin="normal"
        value={name}
        variant="outlined"
        fullWidth
      />
      <TextField
        className={classes.selectField}
        name="image"
        helperText={touched.image ? errors.image : ""}
        error={touched.image && Boolean(errors.image)}
        value={image}
        onChange={handleChange}
        variant="outlined"
        label="Spot Type *"
        select
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {SPOT_TYPES.map(
          spotType => <MenuItem value={`images/${spotType.charAt(0).toUpperCase() + spotType.slice(1)}.png`}>
            {spotType}
          </MenuItem>
        )}
      </TextField>
      <TextField
        className={classes.textField}
        id="description-input"
        name="description"
        helperText={touched.description ? errors.description : ""}
        error={touched.description && Boolean(errors.description)}
        label="Description"
        onChange={handleChange}
        margin="normal"
        value={description}
        variant="outlined"
        rowsMax="5"
        multiline
        fullWidth
      />
      <div className={classes.submitButtonContainer}>
        <Button
          variant="outlined"
          className={classes.cancelBtn}
          onClick={() => {
            console.log('in on onClose');
            onClose()
          }}
        >
          CANCEL
        </Button>
        <Button
          className={classes.submitBtn}
          type="submit"
          color="secondary"
          variant="contained"
          disabled={!isValid}
        >
          ADD SPOT
        </Button>
      </div>
    </form>
  );
};

export default CreateSpotForm;

import axios from 'axios';
// import { getHeaders } from "./index";
import{ ROOT_API_URL } from '../app.config';

const PROFILE_URL = `${ROOT_API_URL}/api/v1/user/profile`;
const USERS_URL = `${ROOT_API_URL}/api/v1/users`;
const PRESIGNED_IMAGE_URL = `${ROOT_API_URL}/api/v1/user/presigned`;


export const getProfile = (identityProviderId) => {
  return axios.get(`${PROFILE_URL}/${identityProviderId}`);
};

export const updateProfile = (userId, payload) => {
  return axios.put(`${USERS_URL}/${userId}`, payload,
  );
};

export const getPreSignedImageURL = userId => {
  return axios.get(`${PRESIGNED_IMAGE_URL}/${userId}`);
};

export const getUserById = (userId) => {
  return axios.get(`${USERS_URL}/${userId}`);
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 388,
    [theme.breakpoints.down('sm')]: {
      width: 285,
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function StandardSelect({ onChange, options, name, value, fieldName }) {
  const classes = useStyles();

  const _handleChange = (event) => {
    onChange(event);
  };

  return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">{name}</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={_handleChange}
          label={name}
          name={fieldName}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map(option => <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>)}
        </Select>
      </FormControl>
  );
}

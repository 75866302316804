import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '8px',
    width: 291,
    maxWidth: 291,
    maxHeight: 72,
    margin: '8px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    textDecoration: 'none'
  },
  cover: {
    width: 75,
  },
}));

export default function ProfilePreviewCard({ profile }) {
  const classes = useStyles();

  return (
    <NavLink to={`/profile-detail/${profile && profile.userId}`} style={{ textDecoration: 'none' }}>
      <Card className={classes.root} variant="outlined" elevation={0}>
        <CardMedia
          className={classes.cover}
          height="72"
          image={profile && profile.photo}
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography align="left" style={{ fontSize: '15px' }}>
              {profile && profile.displayName}
            </Typography>
            <Typography align="left" variant="subtitle1" color="textSecondary" style={{ fontSize: '13px' }}>
              Spots 0 - Footage 0
            </Typography>
          </CardContent>
        </div>

      </Card>
    </NavLink>
  );
}
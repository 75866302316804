import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as profileService from "../../services/profile.service";
import * as actions from '../actions/user.actions';
import { UPDATE_USER_PROFILE, FETCH_USER, fetchUserSpotsAction } from '../actions/user.actions';
import { selectUserProfile } from "../selectors/profile.selectors";
import { FETCH_SPOT } from '../actions/spot.actions';

export function* loginSaga(action) {
  try {
    const authUser = action.payload;

    if (authUser) {
      const result = yield call(profileService.getProfile, authUser.sub);
      console.log('result', result);
      yield put({
        type: actions.SET_USER_PROFILE,
        payload: {
          authUser,
          profile: result.data
        }
      });
    }
  } catch (error) {
    console.error(error)
  }
}

export function* updateUserProfileSaga(action) {
  try {
    const currentProfile = yield select(selectUserProfile);
    const updatedProfile = {
      ...currentProfile,
      ...action.payload,
    };
    const result = yield call(profileService.updateProfile, currentProfile.userId, updatedProfile);
    yield put({ type: `${actions.UPDATE_USER_PROFILE}_SUCCEEDED`, payload: result.data })
  } catch (error) {
    console.error(error)
  }
}

export function* fetchUserSaga(action) {
  try {
    const result = yield call(profileService.getUserById, action.payload);
    yield put({ type: `${actions.FETCH_USER}_SUCCEEDED`, payload: result.data })
    yield put(fetchUserSpotsAction(action.payload))
  } catch (error) {
    console.error(error)
  }
}


export function* watchLoginSaga() {
  yield takeLatest(actions.SET_USER, loginSaga);
};

export function* watchValidateTokenSaga() {
  yield takeLatest(actions.UPDATE_USER_PROFILE, updateUserProfileSaga);
}

export function* watchFetchUserSaga() {
  yield takeLatest(actions.FETCH_USER, fetchUserSaga);
}

export default function* userSagas() {
  yield all([
    watchLoginSaga(),
    watchValidateTokenSaga(),
    watchFetchUserSaga(),
  ]);
}
import {
  FETCH_SPOT,
  FETCH_SPOTS,
  FETCH_NEAR_BY_SPOTS,
  SET_MAP_CENTER_LOCATION,
  SET_SHOW_CREATE_SPOT_MARKER,
  SET_CREATE_SPOT_MARKER_LOCATION,
  FETCH_SPOT_LOCALS,
} from '../actions/spot.actions';

const defaultState = {
  selectedSpot: {
    spotsNearBy: [],
    spotLocalsDetails: [],
  },
  spots: [],
  mapCenterLocation: {
    lng: null, lat: null
  },
  newSpotMarker: {
    show: false,
    location: {
      lon: null, lat: null
    }
  }
};


const spotsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case `${FETCH_SPOT}_SUCCEEDED`:
      return {
        ...state,
        selectedSpot: {
          ...state.selectedSpot.spotsNearBy,
          ...action.payload
        },
      }

    case `${FETCH_SPOTS}_SUCCEEDED`:
      return {
        ...state,
        spots: action.payload,
      }

    case `${FETCH_NEAR_BY_SPOTS}_SUCCEEDED`:
      return {
        ...state,
        selectedSpot: {
          ...state.selectedSpot,
          spotsNearBy: action.payload
        },
      }

    case `${FETCH_SPOT_LOCALS}_SUCCEEDED`:
      return {
        ...state,
        selectedSpot: {
          ...state.selectedSpot,
          spotLocalsDetails: action.payload
        },
      }

    case SET_MAP_CENTER_LOCATION:
      return {
        ...state,
        mapCenterLocation: {
          ...action.payload
        },
      }

    case SET_CREATE_SPOT_MARKER_LOCATION:
      return {
        ...state,
        newSpotMarker: {
          ...state.newSpotMarker,
          location: {
            lat: action.payload.lat,
            lon: action.payload.lng
          },
        },
      }

    case SET_SHOW_CREATE_SPOT_MARKER:
      return {
        ...state,
        newSpotMarker: {
          ...state.newSpotMarker,
          show: action.payload
        }
      }

    default:
      return state;
  }
}

export default spotsReducer;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';
import { Avatar, Card } from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { useSelectedUserProfile } from '../../flux/effects/profile.effects';
import ProfileStatsPanel from '../profile/ProfileStatsPanel';
import ProfileMetaDataPanel from '../profile/ProfileMetaDataPanel';
import { countryToFlag } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    width: '100%',
    height: '752px'
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardContent: {
    paddingTop: 0,
  },
  actionBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(3),
  },
  localBtn: {
    color: 'black',
    marginRight: theme.spacing(2),
  },
  directionsButton: {
    borderWidth: '1px',
    borderColor: '#c8c8c8',
    // flexGrow: 1
  },
  ratingsContainer: {
    textAlign: 'left',
    marginLeft: 0,
    paddingLeft: 0,
  },
  profileHeaderInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '24px',
    justifyContent: 'flex-start'
  }
}));

const ProfileDetailCard = ({ user }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined" elevation={0}>
      <CardHeader
        className={classes.cardHeader}
        action={
          <IconButton aria-label="settings" size="small">
            <MoreVertIcon color="primary" />
          </IconButton>
        }
      />
      <CardContent
        className={classes.cardContent}
      >
        {/*<Typography className={classes.title} variant="h4" align="left" gutterBottom>*/}
        {/*  {user && user.name}*/}
        {/*</Typography>*/}
        {/*<Typography variant="body1" align="left" component="p">*/}
        {/*  /!*{spot && getSubHeader(spot)}*!/ FOO*/}
        {/*</Typography>*/}

        <Box mb={3}>
          <ListItem style={{ paddingLeft: 0, paddingBottom: 0 }}>
            <ListItemAvatar>
              <Avatar src={user.photo} variant="circle" style={{ width: '136px', height: '136px' }}/>
            </ListItemAvatar>

            <Box className={classes.profileHeaderInfo}>
              <Typography
                style={{ fontSize: '22px'}}
              >
                {user.displayName || 'Unknown'}
              </Typography>
              <Typography
                style={{ fontSize: '16px', fontWeight: '500' }}
              >
                Insta: -
              </Typography>
              <Typography
                style={{ fontSize: '16px', fontWeight: '500' }}
              >
                {user.location}
              </Typography>
              <Typography
                style={{ fontSize: '20px', fontWeight: '500' }}
              >
                {countryToFlag(user.country)}
              </Typography>
            </Box>
          </ListItem>
        </Box>

        <div className={classes.actionBtnContainer}>
          <Button
            className={classes.localBtn}
            color="secondary"
            variant="contained"
            disableElevation
          >
            <NotificationsNoneIcon /> FOLLOW
          </Button>
          <Button
            className={classes.directionsButton}
            size="medium"
            color="primary"
            variant="outlined"
          >
            I SKATE WITH {user.displayName}
          </Button>
        </div>

        <Box mt={3} mb={0}>
          <Typography variant="body2" color="textSecondary" align="left" component="p">
            Spot rating for {user.displayName}
          </Typography>

          <Box
            ml={0}
            pl={0}
            className={classes.ratingsContainer}
            component="fieldset"
            borderColor="transparent"
          >
            <Rating
              name="simple-controlled"
              value={4}
              // onChange={(event, newValue) => {
              //   setValue(newValue);
              // }}
            />

            <span>3.6 (12 reviews)</span>
          </Box>

        </Box>

        <Divider />

        <ProfileStatsPanel />

        <Divider />

        <ProfileMetaDataPanel user={user} />

      </CardContent>
    </Card>
  );
};

export default ProfileDetailCard;
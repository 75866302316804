import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import { GeolocateControl, NavigationControl } from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { MAPBOX_API_KEY, MAPBOX_STYLE_URI, SPOT_TYPES } from './config';
import CustomInputBase from './CustomInputBase';
import '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css'
import './Mapbox.scss';
import SpotDetailCard from './SpotDetailCard';
import GenericModal from './GenericModal';
import BottomAppBar from './BottomAppBar';
import {
  useFetchSpotsRequest,
  useSetMapCenterLocationRequest,
  useShowCreateSpotMarker,
  useSpots
} from './flux/effects/spot.effects';
import CreateSpotMarker from './components/spot/CreateSpotMarker';
import ConfirmSpotLocation from './components/spot/ConfirmSpotLocation';
import SpotCreateModalContainer from './components/spot/SpotCreateModalContainer';

// const INIT_MAP_ZOOM = 7;
// const DEBUG_COORDS = [[-122.662155,45.523609],[-122.662315,45.523144],[-122.660721,45.52293],[-122.660706,45.525078],[-122.657021,45.527283],[-119.670815,45.840401],[-118.475449,45.59269],[-118.041252,45.300503],[-117.806168,44.748817],[-117.375885,44.51804],[-116.686485,43.676517],[-116.29454,43.597054],[-115.484192,42.95911],[-114.955215,42.937317],[-114.297195,42.577259],[-113.522118,42.569782],[-113.514229,42.568493],[-112.191368,41.703808],[-112.004745,41.192177],[-111.442169,40.971123],[-111.435616,40.97261],[-111.063347,41.248619],[-109.913651,41.543747],[-106.838028,41.74144],[-106.829308,41.739483],[-106.412231,41.909824],[-106.065033,41.863384],[-105.591888,41.327007],[-105.616821,41.327869],[-105.615601,41.32365],[-105.199684,41.095104],[-103.611366,41.221367],[-102.154099,41.026379],[-100.948013,41.134613],[-99.664139,40.707634],[-99.086197,40.669174],[-98.404671,40.823345],[-96.759956,40.816246],[-95.851944,41.232224],[-95.850235,41.232239],[-95.576935,41.498665],[-93.786148,41.592075],[-93.781418,41.590878],[-93.774475,41.595242],[-93.776726,41.604095],[-93.577736,41.650761],[-90.686722,41.603622],[-90.67672,41.597469],[-90.330223,41.44022],[-88.44577,41.378464],[-87.684525,41.582539],[-87.562256,41.577641],[-86.829018,41.677746],[-86.309029,42.175278],[-83.808258,42.288403],[-83.739662,42.316231],[-83.733208,42.320316],[-83.696381,42.323353],[-83.683693,42.324425],[-83.442337,42.386627],[-83.435127,42.385925],[-83.435677,42.387646],[-83.434547,42.390366],[-83.431313,42.463657],[-83.431213,42.470242],[-83.429535,42.475529],[-83.422592,42.489414],[-83.230957,42.487602],[-83.224419,42.487343],[-83.218124,42.487518],[-83.148544,42.489712],[-83.148117,42.489296],[-83.1483,42.489201],[-83.148109,42.488758]];

const DEFAULT_INIT_COORDS =  [-83.158119, 42.540901]

const Map = ReactMapboxGl({
  accessToken: MAPBOX_API_KEY
});

const geoCoder = new MapboxGeocoder({
  accessToken: MAPBOX_API_KEY,
  placeholder: "   Search...",
});

const onMapLoad = (map, event) => {
  map.addControl(new NavigationControl({showCompass: false}), 'bottom-right');
  map.addControl(new GeolocateControl(), 'bottom-right');
  // use hybrid input
  document
    .getElementById('searchBox')
    .insertBefore(
      geoCoder.onAdd(map),
      document.getElementById("searchBoxIcon")
    );
};

const getIconName = spot => spot.image.split("/")[1].replace(".png", "").toLowerCase();

const MapBoxMap = (props) => {
  // const [spots, setSpots] = useState([]);
  const [spotModalOpen, setSpotModalOpen] = React.useState(false);
  const spots = useSpots()
  const fetchSpots = useFetchSpotsRequest();
  const showCreateSpotMarker = useShowCreateSpotMarker();
  const setMapCenterLocation = useSetMapCenterLocationRequest();

  const [activeSpot, setActiveSpot] = useState();
  const [noRoute, setNoRoute] = useState(false);
  const [directionToSpot, setDirectionToSpot] = useState();
  // const [zoomLevel, setZoomLevel] = useState(INIT_MAP_ZOOM);
  // const currentLocation = useCurrentLocation()
  // const setCurrentLocation = useSetCurrentLocationRequest();

  // const locationRequestSuccess = position => {
  //   setCurrentLocation(position.coords.latitude, position.coords.longitude);
  // };

  const matchSpotFromURL = () => {
    if (props.match.params.spot) {
      const _id = props.match.params.spot.split("+")[0]
      const matchedSpots = spots.filter(s => s.spotId === _id);

      if (matchedSpots.length) {
        setActiveSpot(matchedSpots[0])
        // setZoomLevel(7)
      }
    }
  };

  const generateSpotURLParam = spot => {
    return `${spot.spotId}+${spot.name}:${spot.meta.countyState},${spot.meta.townCity}`
  };

  // const getInitialCoords = () => {
  //   if(activeSpot) {
  //     return [activeSpot.lon, activeSpot.lat];
  //   }
  //
  //   if(currentLocation) {
  //     return currentLocation;
  //   }
  //
  //   return DEFAULT_INIT_COORDS;
  //
  // };

  useEffect(() => {
    // getCurrentLocation(locationRequestSuccess)
    fetchSpots()
    matchSpotFromURL()
  }, []);

  useEffect(() => {
    matchSpotFromURL()
  }, [spots]);

  const clearAll = () => {
    setActiveSpot(null);
    setNoRoute(false);
    setDirectionToSpot(null);
    
    if (props.history.location.pathname !== "/") {
      props.history.push(`/`)
    }
  };

  // clear on geocoder input
  geoCoder.on("loading", clearAll);

  return (
    <Map
      style={MAPBOX_STYLE_URI}
      containerStyle={{
        height: '100vh',
        width: '100vw'
      }}
      center={activeSpot && [activeSpot.lon, activeSpot.lat]} // [-83.158119, 42.540901]
      onStyleLoad={(map,event) => {
        onMapLoad(map, event);
        setMapCenterLocation(map.getCenter());
      }}
      onMove={map => {
        setMapCenterLocation(map.getCenter());
      }}
      // zoom={[INIT_MAP_ZOOM]}
    >
      <BottomAppBar/>
      {SPOT_TYPES.map(spotType => (
        <Layer key={spotType} type="symbol" layout={{ 'icon-image': `marker-${spotType}` }}>
          {spots && spots
            .filter(spot => getIconName(spot) === spotType)
            .map(spot => (
              <Feature
                key={spot.id}
                coordinates={[spot.lon, spot.lat]}
                onClick={() => {
                  setActiveSpot(spot)
                  props.history.push(`/${generateSpotURLParam(spot)}`)
                  // setZoomLevel(7)
                }}
              />
              )
            )
          }
        </Layer>
      ))}

      {showCreateSpotMarker && <CreateSpotMarker />}

      {showCreateSpotMarker && <ConfirmSpotLocation setSpotModalOpen={setSpotModalOpen} />}

      {directionToSpot &&
        <Layer
          key="directionsLayer"
          type="line"
          layout={{ "line-cap": "round", "line-join": "round" }}
          paint={{ "line-color": "#4790E5", "line-width": 5,"line-opacity": 0.75 }}
        >
          <Feature key="directions" coordinates={directionToSpot}/>
        </Layer>
      }
      <GenericModal
        title="Directions unavailable"
        description="Directions are not available between these locations."
        isOpen={noRoute}
        onClose={() => setNoRoute(false)}
      />
      {/*<CustomInputBase onClear={() => geoCoder.clear()}/>*/}
      {activeSpot &&
        <SpotDetailCard
          spot={activeSpot}
          onDirections={directions => {
            directions.then(d => {
              // re write and extract into handler
              if (d.data.code === "NoRoute") {
                setNoRoute(true)
              } else {
                setDirectionToSpot(d.data.routes[0].geometry.coordinates)
              }
            })
          }}
          resetDirections={setDirectionToSpot}
          onClose={clearAll}
        />}
      <SpotCreateModalContainer spotModalOpen={spotModalOpen} setSpotModalOpen={setSpotModalOpen} />
    </Map>);
}

export default MapBoxMap;

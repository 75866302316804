import { all } from 'redux-saga/effects'
import userSagas from './user.sagas';
import spotSagas from './spot.sagas';


export default function* rootSaga() {
  yield all([
    userSagas(),
    spotSagas(),
  ])
}

// export const GOOGLE_API_KEY = "AIzaSyDPbePFpRPR6yBbYk_iRrAeODHMKfQujes";
export const GOOGLE_API_KEY = "AIzaSyCJIDZKtJDuikPR5HZ2DPFvF_rK2wL3Cog";
export const MAPBOX_API_KEY = "pk.eyJ1Ijoic2Vhbndpc2VtYW4iLCJhIjoiY2swdDVrcjBxMDk0YzNjcDVtNzk0a245dSJ9.dlSCO7LdYvPU0IGIvEt4SA";
export const MAPBOX_STYLE_URI = "mapbox://styles/seanwiseman/ck1m7ezhl09t71cow928jf00j";
// AIzaSyCJIDZKtJDuikPR5HZ2DPFvF_rK2wL3Cog

export const SPOT_TYPES = [
  "ditch",
  "diy",
  "pool",
  "park",
  "ramp",
  "street",
  "unknown"
];
import React from 'react';
import AppsIcon from '@material-ui/icons/Apps';
import Button from '@material-ui/core/Button';

const SeeAllButton = ({ onClick, ...props }) => {
  return (
    <Button
      aria-label="settings"
      color="default"
      size="small"
      variant="contained"
      disableElevation
      endIcon={<AppsIcon />}
      onClick={onClick}
    >
      SEE ALL
    </Button>
  );
};

export default SeeAllButton;
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { IconButton } from '@material-ui/core';
import CameraAlt from '@material-ui/icons/CameraAlt';
import useS3Upload from '../../imageUpload/useS3Upload';


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing.unit,
    width: "100%",
    justifyContent: 'center',
    boxShadow: 'none',
  },
  media: {
    height: 250,
    backgroundColor: "grey",
  },
  cameraBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  cameraBtn: {
    backgroundColor: "rgb(84, 83, 83, 0.7)",
    "&:hover": {
      backgroundColor: "rgb(84, 83, 83)"
    },
    color: 'white',
  }
}));

export default function CreateSpotImageSelector({ photo, setPhoto }) {
  const classes = useStyles();

  const onUploadStart = () => console.log("onUploadStart");
  const onUploadReady = (pendingImage, imageUrl) => {
    setPhoto(imageUrl);
  };

  const onError = () => console.log("onError");

  const { getRootProps, getInputProps, open } = useS3Upload({
    imageId: uuidv4(),
    onUploadStart,
    onUploadReady,
    onError
  });

  return (
    <Card className={classes.root} raised={false}>
      <CardActionArea>
        <div {...getRootProps()}>
          <input {...getInputProps()} accept=".jpg,.jpeg,.png" />
        </div>
        <CardMedia
          className={classes.media}
          // image="https://static1.squarespace.com/static/5a79d837aeb625f12ad4e9b2/t/5f4fa47cf41ed579a075175f/1599055049849/Skateboard+England+-+Paul+Scott.jpg?format=1500w"
          image={photo}
          title="Select Image"
        >
          <div className={classes.cameraBtnContainer}>
            <IconButton
              className={classes.cameraBtn}
              onClick={open}
            >
              <CameraAlt />
            </IconButton>
          </div>
        </CardMedia>
      </CardActionArea>
    </Card>
  );
}

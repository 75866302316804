import {
  SET_CURRENT_LOCATION,
  SET_NEW_PROFILE_IMAGE,
  SET_USER_PROFILE,
  FETCH_USER,
  FETCH_USER_SPOTS,
  UPDATE_USER_PROFILE
} from "../actions/user.actions";

const defaultState = {
  authUser: {},
  currentLocation: {
    lat: null,
    lon: null,
  },
  profile: {},
  selectedUser: {
    spots: [],
  },
  newProfileImage: null,
  errors: [],
  token: null,
};


const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return {
        ...state,
        authUser: action.payload.authUser,
        profile: action.payload.profile,
      }

    case `${UPDATE_USER_PROFILE}_SUCCEEDED`:
      console.log('action.payload', action.payload);
      
      return {
        ...state,
        profile: { ...action.payload },
      }

    case SET_NEW_PROFILE_IMAGE:
      return {
        ...state,
        newProfileImage: action.payload.photo,
      };

    case `${FETCH_USER}_SUCCEEDED`:
      return {
        ...state,
        selectedUser: action.payload,
      };

    case `${FETCH_USER_SPOTS}_SUCCEEDED`:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          spots: action.payload,
        },
      };

    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: {
          lat: action.payload.lat,
          lon: action.payload.lon,
        },
      };

    default:
      return state;
  }
}

export default userReducer;
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';

import BottomAppBar from '../BottomAppBar';
import SkatesWithCard from '../components/cards/SkatesWithCard';
import SpotsByCard from '../components/cards/SpotsByCard';
import FootageByCard from '../components/cards/FootageByCard';
import { useFetchUserRequest, useSelectedUserProfile } from '../flux/effects/profile.effects';
import ProfileDetailCard from '../components/cards/ProfileDetailCard';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
    paddingTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  pageContentContainer: {
    padding: theme.spacing(10, 12, 12, 12),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10, 1, 12, 1),
    },
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const ProfileDetailPage = (props) => {
  const classes = useStyles();
  const userId = props.match.params.userId;
  const fetchUser = useFetchUserRequest();
  const user = useSelectedUserProfile();

  useEffect(() => {
    fetchUser(userId)
  }, [])

  return (
    <React.Fragment>
      <BottomAppBar hideAddSpot />
      <div className={classes.pageContentContainer}>

        <Breadcrumbs aria-label="home">
          <NavLink color="inherit" to="/">
            Map
          </NavLink>
          <Typography color="textPrimary">Profile</Typography>
        </Breadcrumbs>

        <div className={classes.gridRoot}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={5}>
              <ProfileDetailCard user={user} />
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <FootageByCard />
            </Grid>

            <Grid item xs={12}>
              <SkatesWithCard />
            </Grid>

            <Grid item xs={12}>
              <SpotsByCard spots={user.spots} />
            </Grid>

          </Grid>
        </div>

      </div>

    </React.Fragment>
  )
}

export default ProfileDetailPage;

import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as spotService from "../../services/spot.service";
import { reverseGeoLookUp } from '../../services/mapbox.service';
import {
  CREATE_SPOT,
  FETCH_SPOTS,
  FETCH_SPOT,
  SET_CREATE_SPOT_MARKER_LOCATION,
  FETCH_NEAR_BY_SPOTS,
  ADD_SPOT_LOCAL,
  REMOVE_SPOT_LOCAL,
  FETCH_SPOT_LOCALS,
} from '../actions/spot.actions';
import { FETCH_USER_SPOTS } from '../actions/user.actions';
import { extractTownAndCounty } from '../../utils';
import { fetchUserAction } from '../actions/user.actions';
import { selectUserProfile } from '../selectors/profile.selectors';
import * as profileService from '../../services/profile.service';
import * as actions from '../actions/user.actions';


export function* createSpotSaga(action) {
  try {
    const response = yield call(reverseGeoLookUp, action.payload.lat, action.payload.lon)
    const { town, county } = extractTownAndCounty(response.data);

    action.payload["meta"] = {
      countyState: county,
      townCity: town
    }

    const result = yield call(spotService.creatSpot, action.payload);
    yield put({ type: `${CREATE_SPOT}_SUCCEEDED`, payload: result.data })
    yield put({ type: FETCH_SPOTS });
    yield put({ type: SET_CREATE_SPOT_MARKER_LOCATION, payload: {lat: null, lon: null} });
  } catch (error) {
    console.error(error)
  }
}

export function* fetchSpotSaga(action) {
  try {
    const spot = yield call(spotService.fetchSpot, action.payload);
    yield put({ type: `${FETCH_SPOT}_SUCCEEDED`, payload: spot.data })
    yield put(fetchUserAction(spot.data.creatorId))

    const spotsNearBy = yield call(spotService.fetchSpotByNearBy, action.payload);
    yield put({ type: `${FETCH_NEAR_BY_SPOTS}_SUCCEEDED`, payload: spotsNearBy.data })

    if (spot.data.locals) {
      const localsData = yield all(spot.data.locals.map(local => {
        return call(profileService.getUserById, local)
      }));
      const spotLocalsDetails = localsData.map(item => item.data)
      yield put({ type: `${FETCH_SPOT_LOCALS}_SUCCEEDED`, payload: spotLocalsDetails })
    }
  } catch (error) {
    console.error(error)
  }
}


export function* fetchSpotsSaga() {
  try {
    const result = yield call(spotService.fetchSpots);
    yield put({ type: `${FETCH_SPOTS}_SUCCEEDED`, payload: result.data })
  } catch (error) {
    console.error(error)
  }
}

export function* fetchUserSpotsSaga(action) {
  try {
    const result = yield call(spotService.fetchSpotByUserId, action.payload);
    yield put({ type: `${FETCH_USER_SPOTS}_SUCCEEDED`, payload: result.data })
  } catch (error) {
    console.error(error)
  }
}

export function* updateSpotLocalsSaga(action) {
  try {
    const { userId, spot } = action.payload;
    if (!spot.locals) {
      // spot does not have the locals var, add it...
      spot.locals = []
    }

    if (spot.locals.includes(userId)) {
      //remove it
      spot.locals.splice(spot.locals.indexOf(userId), 1);
    } else {
      spot.locals.push(userId)
    }

    const result = yield call(spotService.updateSpot, spot.spotId, spot);
    yield put({ type: FETCH_SPOT, payload: spot.spotId })
  } catch (error) {
    console.error(error)
  }
}

export function* watchCreateSpotSaga() {
  yield takeLatest(CREATE_SPOT, createSpotSaga);
}

export function* watchFetchSpotSaga() {
  yield takeLatest(FETCH_SPOT, fetchSpotSaga);
}

export function* watchFetchSpotsSaga() {
  yield takeLatest(FETCH_SPOTS, fetchSpotsSaga);
}

export function* watchFetchUserSpotsSaga() {
  yield takeLatest(FETCH_USER_SPOTS, fetchUserSpotsSaga);
}

export function* watchUpdateSpotLocalsSaga() {
  yield takeLatest(ADD_SPOT_LOCAL, updateSpotLocalsSaga);
  yield takeLatest(REMOVE_SPOT_LOCAL, updateSpotLocalsSaga);
}

export default function* spotSagas() {
  yield all([
    watchCreateSpotSaga(),
    watchFetchSpotSaga(),
    watchFetchSpotsSaga(),
    watchFetchUserSpotsSaga(),
    watchUpdateSpotLocalsSaga(),
  ]);
}
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ArrowLeftIcon from '@material-ui/icons/ArrowBack';
import ArrowRightIcon from '@material-ui/icons/ArrowForward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import RoundAboutIcon from '@material-ui/icons/Autorenew';
import DestinationIcon from '@material-ui/icons/EmojiFlags';

import { metersToMiles } from './utils';

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

const iconMap = {
  left: <ArrowLeftIcon />,
  right: <ArrowRightIcon />,
  roundabout: <RoundAboutIcon />,
  destination: <DestinationIcon />,
  default: <ArrowDownIcon />,
};

const getIcon = instruction => {
  if (instruction.includes('destination')) {
    return iconMap.destination;
  }
  if (instruction.includes('roundabout')) {
    return iconMap.roundabout;
  }
  if (instruction.includes('left')) {
    return iconMap.left;
  }
  if (instruction.includes('right')) {
    return iconMap.right;
  }

  return iconMap.default;
};

export default function RouteStep({ step, ...props }) {
  // const classes = useStyles();

  return (
    <ListItem>
      <ListItemIcon>
        {getIcon(step.maneuver.instruction)}
      </ListItemIcon>
      <ListItemText
        primary={step.maneuver.instruction}
        secondary={`${metersToMiles(step.distance)} miles`}
      />
    </ListItem>
  );
}
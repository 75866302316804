import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ProfileFormButtons from './ProfileFormButtons';
import StandardTextInput from '../forms/StandardTextInput';
import StandardSelect from '../forms/StandardSelect';
import { countries } from '../../countries';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function ProfileForm(props) {
  const {
    values: {
      biography,
      country,
      discipline,
      displayName,
      location,
      stance,
      signatureMoves,
      sponsors,
    },
    errors,
    touched,
    handleChange,
    handleSubmit,
    isValid,
    onClose,
  } = props;
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <StandardTextInput
        name="displayName"
        value={displayName}
        label="Name"
        errors={errors}
        handleChange={handleChange}
        touched={touched}
      />
      <StandardTextInput
        name="location"
        value={location}
        label="Location"
        errors={errors}
        handleChange={handleChange}
        touched={touched}
      />
      <StandardSelect
        name="Country"
        fieldName="country"
        value={country}
        onChange={handleChange}
        options={countries.map(country => ({ value: country.code, name: country.label }))}
      />
      <StandardSelect
        name="Stance"
        fieldName="stance"
        value={stance}
        onChange={handleChange}
        options={
          [
            { value: "regular", name: "Regular" },
            { value: "goofy", name: "Goofy" },
          ]
        }
      />
      <StandardSelect
        name="Discipline"
        fieldName="discipline"
        value={discipline}
        onChange={handleChange}
        options={
          [
            { value: "street", name: "Street" },
            { value: "park", name: "Park" },
            { value: "vert", name: "Vert" },
            { value: "pool", name: "Pool" },
            { value: "freestyle", name: "freestyle" },
          ]
        }
      />
      <StandardTextInput
        name="signatureMoves"
        value={signatureMoves}
        label="Signature Moves"
        errors={errors}
        handleChange={handleChange}
        touched={touched}
      />
      <StandardTextInput
        name="sponsors"
        value={sponsors}
        label="Sponsors"
        errors={errors}
        handleChange={handleChange}
        touched={touched}
      />
      <StandardTextInput
        name="biography"
        value={biography}
        label="Biography"
        errors={errors}
        handleChange={handleChange}
        touched={touched}
        multiline
        rows={4}
      />
      <ProfileFormButtons onClose={onClose} onSave={handleSubmit}/>
    </form>
  );
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CreateSpotFormContainer from '../forms/CreateSpotForm';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '420px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[ 5 ],
    padding: "8px",
    paddingBottom: 0,
    borderRadius: '4px',
    "&:focus": {
      outline: 'none'
    },
  },
  modalTitle: {
    fontSize: '20px',
    paddingLeft: '24px',
    marginBottom: '16px',
  },
  modalDescription: {
    fontSize: '16px',
    paddingLeft: '24px',
    paddingRight: '24px',
    marginTop: '8px',
  },
  large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  avatarBadge: {
    color: "white"
  },
  closeContainer: {
    width: '100%',
  },
  badgeRoot: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(8),
  },
  iconCloseButton: {
    backgroundColor: "rgb(84, 83, 83, 0.7)",
    "&:hover": {
      backgroundColor: "rgb(84, 83, 83)"
    },
  },
  closeBtn: {
    width: '103px',
    height: '36px',
    alignSelf: 'flex-end',
    marginTop: '30px',
  },
}));


export default function SpotCreateModal({ spotModalOpen, setSpotModalOpen }) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={spotModalOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={spotModalOpen}>
        <div className={classes.paper}>
          <CreateSpotFormContainer
            onSuccess={() => setSpotModalOpen(false)}
            onClose={() => setSpotModalOpen(false)}
          />
        </div>
      </Fade>
    </Modal>
  );
}
